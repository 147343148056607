/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as xlsx from 'xlsx/xlsx.mjs';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import '../App.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Container,
  Stack,
  Typography,
  Box,
  Card,
  TextField,
  Button,
  Checkbox,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ImageListItem,
  ImageList,
  Snackbar,
  Slide,
  styled,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getAllNetworks } from '../services/networkServices';
import {
  updatePodcast,
  getPodcastById,
  deletePodcast,
  getFilesByPodcastId,
  addFile,
  deleteFile,
  getCategory,
} from '../services/podcastServices';
import {
  getProgrammaticPodcastByTPXId,
  addProgrammaticPodcast,
  updateProgrammaticPodcast,
  deleteProgrammaticPodcast,
} from '../services/programmaticService';
import { getUpdates } from '../services/thirdPartyApiService';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
export default function PodcastDetail() {
  let timeout = 3000;
  const { id } = useParams();
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem('userData')).isAdmin;
  const [data, setData] = useState({});
  const [networkData, setNetworkData] = useState({});
  const [networkNames, setNetworkNames] = useState([]);
  const [files, setFiles] = useState([]);
  const [progfiles, setprogfiles] = useState([]);
  const [tab, setTab] = useState(0);
  const [categoriesChip, setCategoriesChip] = useState([]);
  const itunesCategories = [
    { id: 1, name: 'Arts' },
    { id: 2, name: 'Books' },
    { id: 3, name: 'Design' },
    { id: 4, name: 'Fashion & Beauty' },
    { id: 5, name: 'Food' },
    { id: 6, name: 'Performing Arts' },
    { id: 7, name: 'Visual Arts' },
    { id: 8, name: 'Business' },
    { id: 9, name: 'Careers' },
    { id: 10, name: 'Entrepreneurship' },
    { id: 11, name: 'Investing' },
    { id: 12, name: 'Management' },
    { id: 13, name: 'Marketing' },
    { id: 14, name: 'Non-Profit' },
    { id: 15, name: 'Comedy' },
    { id: 16, name: 'Comedy Interviews' },
    { id: 17, name: 'Improv' },
    { id: 18, name: 'Stand-Up' },
    { id: 19, name: 'Education' },
    { id: 20, name: 'Courses' },
    { id: 21, name: 'How To' },
    { id: 22, name: 'Language Learning' },
    { id: 23, name: 'Self-Improvement' },
    { id: 24, name: 'Fiction' },
    { id: 25, name: 'Comedy Fiction' },
    { id: 26, name: 'Drama' },
    { id: 27, name: 'Science Fiction' },
    { id: 28, name: 'Government' },
    { id: 29, name: 'History' },
    { id: 30, name: 'Health & Fitness' },
    { id: 31, name: 'Alternative Health' },
    { id: 32, name: 'Fitness' },
    { id: 33, name: 'Medicine' },
    { id: 34, name: 'Mental Health' },
    { id: 35, name: 'Nutrition' },
    { id: 36, name: 'Sexuality' },
    { id: 37, name: 'Kids & Family' },
    { id: 38, name: 'Education for Kids' },
    { id: 39, name: 'Parenting' },
    { id: 40, name: 'Pets & Animals' },
    { id: 41, name: 'Stories for Kids' },
    { id: 42, name: 'Leisure' },
    { id: 43, name: 'Animation & Manga' },
    { id: 44, name: 'Automotive' },
    { id: 45, name: 'Aviation' },
    { id: 46, name: 'Crafts' },
    { id: 47, name: 'Games' },
    { id: 48, name: 'Hobbies' },
    { id: 49, name: 'Home & Garden' },
    { id: 50, name: 'Video Games' },
    { id: 51, name: 'Music' },
    { id: 52, name: 'Music Commentary' },
    { id: 53, name: 'Music History' },
    { id: 54, name: 'Music Interviews' },
    { id: 55, name: 'News' },
    { id: 56, name: 'Business News' },
    { id: 57, name: 'Daily News' },
    { id: 58, name: 'Entertainment News' },
    { id: 59, name: 'News Commentary' },
    { id: 60, name: 'Politics' },
    { id: 61, name: 'Sports News' },
    { id: 62, name: 'Tech News' },
    { id: 63, name: 'Religion & Spirituality' },
    { id: 64, name: 'Buddhism' },
    { id: 65, name: 'Christianity' },
    { id: 66, name: 'Hinduism' },
    { id: 67, name: 'Islam' },
    { id: 68, name: 'Judaism' },
    { id: 69, name: 'Religion' },
    { id: 70, name: 'Spirituality' },
    { id: 71, name: 'Science' },
    { id: 72, name: 'Astronomy' },
    { id: 73, name: 'Chemistry' },
    { id: 74, name: 'Earth Sciences' },
    { id: 75, name: 'Life Sciences' },
    { id: 76, name: 'Mathematics' },
    { id: 77, name: 'Natural Sciences' },
    { id: 78, name: 'Nature' },
    { id: 79, name: 'Physics' },
    { id: 80, name: 'Social Sciences' },
    { id: 81, name: 'Society & Culture' },
    { id: 82, name: 'Documentary' },
    { id: 83, name: 'Personal Journals' },
    { id: 84, name: 'Philosophy' },
    { id: 85, name: 'Places & Travel' },
    { id: 86, name: 'Relationships' },
    { id: 87, name: 'Sports' },
    { id: 88, name: 'Baseball' },
    { id: 89, name: 'Basketball' },
    { id: 90, name: 'Cricket' },
    { id: 91, name: 'Fantasy Sports' },
    { id: 92, name: 'Football' },
    { id: 93, name: 'Golf' },
    { id: 94, name: 'Hockey' },
    { id: 95, name: 'Rugby' },
    { id: 96, name: 'Soccer' },
    { id: 97, name: 'Swimming' },
    { id: 98, name: 'Tennis' },
    { id: 99, name: 'Volleyball' },
    { id: 100, name: 'Wilderness' },
    { id: 101, name: 'Wrestling' },
    { id: 102, name: 'Technology' },
    { id: 103, name: 'True Crime' },
    { id: 104, name: 'TV & Film' },
    { id: 105, name: 'After Shows' },
    { id: 106, name: 'Film History' },
    { id: 107, name: 'Film Interviews' },
    { id: 108, name: 'Film Reviews' },
    { id: 109, name: 'TV Reviews' },
  ];
  const regions = [
    { id: 'CA', name: 'CA' },
    { id: 'BC', name: 'BC' },
    { id: 'AB', name: 'Alberta' },
    { id: 'SK', name: 'Saskatchewan' },
    { id: 'MA', name: 'Manitoba' },
    { id: 'ON', name: 'Ontario' },
    { id: 'QB', name: 'Quebec' },
    { id: 'AT', name: 'Atlantic Canada' },
    { id: 'US', name: 'US' },
    { id: 'AU', name: 'AU' },
    { id: 'UK', name: 'UK' },
  ];

  const TableCellDesign = styled(TableCell)`
    font-size: 0.75rem;
    border: 1px solid rgba(241, 243, 244, 1);
    .tooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .artwork {
      width: 70px;
      height: 70px;
      border-radius: 15px;
    }
  `;

  const tableRef = useRef(null);
  useEffect(() => {
    let nid = 0;
    getPodcastById(id).then((res) => {
      if (res.success) {
        setData(res.data);
        const temp = res.data.categories;
        nid = res.data.networkId;
        if (temp !== null) {
          setCategoriesChip(temp.split(','));
        }
      }
    });
    getAllNetworks().then((res) => {
      if (res.success) {
        let temp = res.data.sort((a, b) =>
          // eslint-disable-next-line no-nested-ternary
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
        );
        setNetworkNames([...temp]);
        temp = temp.filter((e) => {
          return e.id === nid;
        });
        setNetworkData(temp[0]);
      }
    });
    getProgrammaticPodcastByTPXId(id).then((res) => {
      if (res.success) {
        setprogfiles([...res.data]);
      }
    });
    getFilesByPodcastId(id).then((res) => {
      if (res.success) {
        setFiles(res.data);
      }
    });
  }, []);
  const handleGetUpdates = (id) => {
    getUpdates(id).then((res) => {
      if (res.status !== 'false') {
        data.description = res.description;
        data.author = res.author;
        data.artworkUrl = res.artwork;
        if (res.itunesId !== null) {
          data.iTunesID = res.itunesId.toString();
        }
        data.rssFeedUrl = res.originalUrl;
        getCategory(res.originalUrl).then((re) => {
          setCategoriesChip([...re.data]);
        });
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Data Updated Successfully');
        handleDataChange('toastStatus', 'success');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
        setData({ ...data });
      } else {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Podcast does not belong to Podcast Index');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1500);
      }
    });
  };
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setTimeout(() => {
      if (
        document.getElementsByClassName(
          'css-1ynrp2g-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator'
        ) &&
        newValue === 1
      ) {
        const element = document.getElementsByClassName(
          'css-1ynrp2g-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator'
        )[0];
        element.remove();
      }
    }, 100);
  };

  const handleDataChange = (fld, val) => {
    if (isAdmin) {
      if (fld === 'categories') {
        data[fld] = val.toString();
        setData({ ...data });
        setCategoriesChip([...val]);
      } else {
        data[fld] = val;
        setData({ ...data });
      }
    }
  };

  const nllTheBlank = (data) => {
    Object.keys(data).forEach((elem) => {
      data[elem] = data[elem] === '' ? null : data[elem];
    });
    return data;
  };

  const handlePodcast = (data, op) => {
    if (op === 'save') {
      data = nllTheBlank(data);
      updatePodcast(data).then((res) => {
        if (res.success) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Data Updated Successfully');
          handleDataChange('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    }
  };
  const handleFile = (data, i) => {
    addFile(id, data).then((res) => {
      if (res.success) {
        data.isEdit = false;
        data.id = res.data.id;
        setFiles([...files]);
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'File Added Successfully');
        handleDataChange('toastStatus', 'success');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else if (res.status === 403) {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', "You don't have admin rights");
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Something went wrong!');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
      }
    });
  };
  const handleProgrammaticPodcast = (data, i, op) => {
    if (op === 'add') {
      addProgrammaticPodcast(data[i]).then((res) => {
        if (res.success) {
          data[i].isEdit = false;
          data[i].isNew = false;
          data[i].id = res.data.id;
          setprogfiles([...data]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Programmatic Podcast Added Successfully');
          handleDataChange('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    } else if (op === 'update') {
      updateProgrammaticPodcast(data[i]).then((res) => {
        if (res.success) {
          data[i].isEdit = false;
          setprogfiles([...data]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Programmatic Podcast Updated Successfully');
          handleDataChange('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    }
  };

  const handleDelete = () => {
    if (data.op === 'podcast') {
      deletePodcast(id).then((res) => {
        if (res.success) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Podcast Deleted Successfully');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
            navigate(`/dashboard/podcasts`, { replace: true });
          }, 1000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    } else if (data.op === 'propodcast') {
      deleteProgrammaticPodcast(data.deletedata).then((res) => {
        if (res.success) {
          progfiles.splice(data.deletenum, 1);
          setprogfiles([...progfiles]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Programmatic Podcast Deleted Successfully');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    } else if (data.op === 'file') {
      deleteFile(data.deletedata).then((res) => {
        if (res.success) {
          files.splice(data.deletenum, 1);
          setFiles([...files]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'File Deleted Successfully');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    }
  };

  return (
    <Page title="Podcast Detail">
      {!!data.title && networkData !== undefined && (
        <Container maxWidth="xxl">
          <Stack
            style={{ margin: '0 0 2rem 1rem' }}
            className="mb-2"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row">
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography
                  onClick={() => {
                    navigate(`/dashboard/podcasts`, { replace: true });
                  }}
                >
                  Podcast
                </Typography>
                <Typography
                  color="text.primary"
                  style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  <div className="tooltip" title={data.title}>
                    {data.title}
                  </div>
                </Typography>
              </Breadcrumbs>
              &nbsp;;&nbsp;
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography
                  onClick={() => {
                    navigate(`/dashboard/podcasts`, { replace: true });
                  }}
                >
                  Network
                </Typography>
                <Typography color="text.primary">{networkData.name}</Typography>
              </Breadcrumbs>
            </Stack>
            {isAdmin ? (
              <Stack direction="row">
                <Button
                  onClick={() => {
                    handleGetUpdates(data.feedId);
                  }}
                  variant="outlined"
                  style={{ width: '160px', marginRight: '1rem' }}
                  color="success"
                >
                  Get Updates
                </Button>
                {/* Save Btn */}
                <Button
                  onClick={() => {
                    handlePodcast(data, 'save');
                  }}
                  variant="contained"
                  style={{ width: '160px', marginRight: '1rem' }}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    handleDataChange('openDialog', true);
                    handleDataChange('type', 'podcast');
                    handleDataChange('op', 'podcast');
                  }}
                  variant="outlined"
                  color="error"
                  style={{ width: '160px' }}
                >
                  Delete
                </Button>
                <Dialog
                  open={data.openDialog || false}
                  onClose={() => {
                    handleDataChange('openDialog', false);
                  }}
                >
                  <DialogContent>
                    {data.type === 'other' ? (
                      <Typography>Are you sure you want to delete this?</Typography>
                    ) : (
                      <Typography>Are you sure you want to delete {data.title} podcast</Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleDataChange('openDialog', false)}>Cancel</Button>
                    <Button
                      onClick={() => {
                        handleDataChange('openDialog', false);
                        handleDelete();
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Stack>
            ) : null}
          </Stack>
          <Card style={{ padding: '1rem 2rem 2rem', minHeight: '40rem' }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="About" />
              <Tab label="Categories" />
              <Tab label="Languages & Geo" />
              <Tab label="Third-Party Tracking" />
              <Tab label="Transaction Type" />
              <Tab label="Ad Read Type, Position and CPM" />
              <Tab label="Monthly Downloads & Impressions" />
              {/* <Tab label="Ad Position" /> */}
              {/* <Tab label="CPM" /> */}
              <Tab label="Restrictions" />
            </Tabs>

            {/* About */}
            <TabPanel value={tab} index={0} style={{ marginBottom: '6rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                About:
              </Typography>

              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <Stack direction={'column'} alignItems={'center'} style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                  <ImageListItem>
                    {data.artworkUrl !== null ? (
                      <img
                        src={data.artworkUrl}
                        alt={data.title}
                        loading="lazy"
                        style={{ width: '200px', height: '200px', marginBottom: '1rem', borderRadius: '10px' }}
                      />
                    ) : (
                      <img
                        src="/static/mock-images/avatars/avatar_default.jpg"
                        alt="demo"
                        loading="lazy"
                        style={{ width: '200px', height: '200px', marginBottom: '1rem' }}
                      />
                    )}
                  </ImageListItem>
                </Stack>
                <Stack direction={'column'} alignItems={'center'} style={{ marginBottom: '1rem', marginLeft: '3rem' }}>
                  <TextField
                    label="Title"
                    style={{ marginBottom: '1rem' }}
                    value={data.title}
                    onChange={(e) => {
                      if (data.rssFeedUrl === null) {
                        handleDataChange('title', e.target.value);
                      }
                    }}
                    placeholder="Title"
                  />
                  <TextField
                    label="Author"
                    style={{ marginBottom: '1rem' }}
                    value={data.author === null ? '' : data.author}
                    onChange={(e) => {
                      if (data.rssFeedUrl === null) {
                        handleDataChange('author', e.target.value);
                      }
                    }}
                    placeholder=""
                  />
                  <FormControl sx={{ minWidth: 220 }}>
                    <InputLabel>Network Name</InputLabel>
                    <Select
                      value={data.networkId}
                      label="Network Name"
                      onChange={(e) => {
                        handleDataChange('networkId', e.target.value);
                      }}
                    >
                      {networkNames.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>

              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.description === null ? '' : data.description}
                onChange={(e) => {
                  handleDataChange('description', e.target.value);
                }}
                placeholder=""
              />
              <TextField
                fullWidth
                label="Podcast Notes"
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.podcastNotes}
                onChange={(e) => {
                  handleDataChange('podcastNotes', e.target.value);
                }}
                placeholder=""
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="RssFieldUrl"
                variant="outlined"
                style={{ marginBottom: '1rem' }}
                value={data.rssFeedUrl === null ? '' : data.rssFeedUrl}
                onChange={(e) => {
                  handleDataChange('rssFeedUrl', e.target.value);
                }}
              />
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Files:
                {isAdmin && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                      const dt = files;
                      const ct = {
                        fileName: '',
                        url: '',
                        networkId: 'id',
                        isEdit: true,
                        isNew: true,
                      };
                      dt.push(ct);
                      setFiles([...dt]);
                    }}
                  >
                    <Iconify icon="eva:plus-outline" width={24} height={24} />
                  </Button>
                )}
              </Typography>
              {files.length > 0 ? (
                files.map((fl, i) => (
                  <>
                    {!fl.isEdit ? (
                      <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                        <a style={{ flex: 15 }} href={fl.url}>
                          {fl.fileName}
                        </a>
                        {isAdmin && (
                          <Button
                            style={{ flex: 1 }}
                            onClick={() => {
                              handleDataChange('op', 'file');
                              handleDataChange('deletedata', fl.id);
                              handleDataChange('deletenum', i);
                              handleDataChange('type', 'other');
                              handleDataChange('openDialog', true);
                            }}
                          >
                            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                          </Button>
                        )}
                      </Stack>
                    ) : (
                      <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                        <TextField
                          style={{ flex: 5 }}
                          value={fl.fileName}
                          onChange={(e) => {
                            const dt = files;
                            dt[i].fileName = e.target.value;
                            setFiles([...dt]);
                          }}
                          placeholder="File Name"
                        />
                        <TextField
                          style={{ flex: 8, marginLeft: '1rem' }}
                          value={fl.url}
                          onChange={(e) => {
                            const dt = files;
                            dt[i].url = e.target.value;
                            setFiles([...dt]);
                          }}
                          placeholder="URL"
                        />
                        <Button
                          style={{ flex: 0.5, marginLeft: '1rem' }}
                          variant="contained"
                          onClick={() => {
                            const file = files[i];
                            if (file.fileName !== '' && file.url !== '') {
                              const dt = new FormData();
                              dt.append('fileName', file.fileName);
                              dt.append('url', file.url);
                              handleFile(files[i], 'add', i);
                            } else {
                              handleDataChange('toastFlag', true);
                              handleDataChange('toastMsg', 'Filename and URL are mandatory');
                              handleDataChange('toastStatus', 'error');
                              clearTimeout(timeout);
                              timeout = setTimeout(() => {
                                handleDataChange('toastFlag', false);
                              }, 1000);
                            }
                          }}
                        >
                          Add
                        </Button>
                      </Stack>
                    )}
                    <hr />
                  </>
                ))
              ) : (
                <Typography>No Files found!!</Typography>
              )}
            </TabPanel>

            {/* Category */}
            <TabPanel value={tab} index={1}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Categories:
              </Typography>
              <Autocomplete
                value={categoriesChip}
                multiple
                fullWidth
                freeSolo
                id="tags-filled"
                options={itunesCategories.map((dt) => dt.name)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <Chip variant="filled" label={option} {...getTagProps({ index })} />)
                }
                renderInput={(params) => <TextField {...params} placeholder="Type and select" />}
                onChange={(e, value) => {
                  handleDataChange('categories', value);
                }}
                style={{ maxWidth: '1250px' }}
              />
            </TabPanel>

            {/* Lang and Geo */}
            <TabPanel value={tab} index={2}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Languages:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>English </Typography>
                {data.language_En === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.language_En}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('language_En', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.language_En}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('language_En', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>French </Typography>
                {data.language_Fr === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.language_Fr}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('language_Fr', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.language_Fr}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('language_Fr', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>

              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Geo:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>Canada </Typography>
                {data.geo_Canada === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.geo_Canada}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('geo_Canada', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.geo_Canada}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('geo_Canada', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>Provincial </Typography>
                {data.geo_Provincial === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.geo_Provincial}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('geo_Provincial', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.geo_Provincial}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('geo_Provincial', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>USA </Typography>
                {data.geo_US === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.geo_US}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('geo_US', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.geo_US}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('geo_US', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>Municipal / FSA </Typography>
                {data.geo_Municipal === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.municipalFSA}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('geo_Municipal', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.geo_Municipal}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('geo_Municipal', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
            </TabPanel>

            {/* Third-Party Tracking */}
            <TabPanel value={tab} index={3}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Third-Party Tracking:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>Chartable </Typography>
                {data.canTrack_Chartable === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.canTrack_Chartable}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('canTrack_Chartable', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.canTrack_Chartable}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('canTrack_Chartable', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>Podsights </Typography>
                {data.canTrack_Podsights === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.canTrack_Podsights}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('canTrack_Podsights', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.canTrack_Podsights}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('canTrack_Podsights', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>LoopMe </Typography>
                {data.canTrack_LoopMe === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.canTrack_LoopMe}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('canTrack_LoopMe', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.canTrack_LoopMe}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('canTrack_LoopMe', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              {data.canTrack_Notes === null ? (
                <>
                  <TextField
                    label="Can Track Note"
                    multiline
                    rows={4}
                    style={{ width: '700px', marginBottom: '1rem' }}
                    value={networkData.canTrack_Notes === null ? '' : networkData.canTrack_Notes}
                    onChange={(e) => {
                      handleDataChange('canTrack_Notes', e.target.value);
                    }}
                  />
                  {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                </>
              ) : (
                <TextField
                  label="Can Track Note"
                  multiline
                  rows={4}
                  style={{ width: '700px', marginBottom: '1rem' }}
                  value={data.canTrack_Notes === null ? '' : data.canTrack_Notes}
                  onChange={(e) => {
                    handleDataChange('canTrack_Notes', e.target.value);
                  }}
                  placeholder=""
                />
              )}
            </TabPanel>

            {/* Transaction type */}
            <TabPanel value={tab} index={4}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Transaction Type:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '8rem' }}>Programmatic </Typography>
                {data.transactionType_Programmatic === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.transactionType_Programmatic}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('transactionType_Programmatic', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.transactionType_Programmatic}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('transactionType_Programmatic', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '8rem' }}>Direct </Typography>
                {data.transactionType_Direct === null ? (
                  <>
                    <ToggleButtonGroup
                      color="primary"
                      value={networkData.transactionType_Direct}
                      exclusive
                      aria-label="Platform"
                      onChange={(e, val) => {
                        handleDataChange('transactionType_Direct', val);
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      <ToggleButton value={null}>Not set</ToggleButton>
                      <ToggleButton value={true}>Yes</ToggleButton>
                      <ToggleButton value={false}>No</ToggleButton>
                    </ToggleButtonGroup>
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </>
                ) : (
                  <ToggleButtonGroup
                    color="primary"
                    value={data.transactionType_Direct}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('transactionType_Direct', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Stack>
              {data.transactionType_Programmatic === true ? (
                <>
                  <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                    Podcasts in Programmatic Package:
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    flexShrink={0}
                    sx={{ my: 1 }}
                    justifyContent="space-between"
                    style={{ width: '100%' }}
                  >
                    {isAdmin && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          const dt = progfiles;
                          const ct = {
                            title: '',
                            link: '',
                            tpX_Id: id,
                            isEdit: true,
                            isNew: true,
                          };
                          dt.push(ct);
                          setprogfiles([...dt]);
                        }}
                      >
                        <Iconify icon="eva:plus-outline" />
                      </Button>
                    )}
                    <DownloadTableExcel
                      filename={`${data.title} Programmatic Podcasts`}
                      sheet={`${data.title} Programmatic Podcasts`}
                      currentTableRef={tableRef.current}
                    >
                      <Button
                        disableRipple
                        color="inherit"
                        style={{ display: 'flex', marginLeft: 'auto' }}
                        endIcon={<Iconify icon="material-symbols:upload-file-outline" />}
                      >
                        Export Podcast list
                      </Button>
                    </DownloadTableExcel>
                  </Stack>
                  {progfiles.length > 0 ? (
                    progfiles.map((fl, i) => (
                      <>
                        {fl.isEdit ? (
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            style={{ marginBottom: '1rem', marginTop: '1rem' }}
                          >
                            <TextField
                              style={{ flex: 5 }}
                              value={fl.title}
                              onChange={(e) => {
                                const dt = progfiles;
                                dt[i].title = e.target.value;
                                setprogfiles([...dt]);
                              }}
                              placeholder="Title"
                            />
                            <TextField
                              style={{ flex: 8, marginLeft: '1rem' }}
                              value={fl.link}
                              onChange={(e) => {
                                const dt = progfiles;
                                dt[i].link = e.target.value;
                                setprogfiles([...dt]);
                              }}
                              placeholder="Link"
                            />
                            {fl.isNew ? (
                              <Button
                                style={{ flex: 0.5, marginLeft: '1rem' }}
                                variant="contained"
                                onClick={() => {
                                  const file = progfiles[i];
                                  if (file.title !== '' && file.link !== '') {
                                    const dt = new FormData();
                                    dt.append('title', file.title);
                                    dt.append('link', file.link);
                                    handleProgrammaticPodcast(progfiles, i, 'add');
                                  } else {
                                    handleDataChange('toastFlag', true);
                                    handleDataChange('toastMsg', 'Title and link are mandatory');
                                    handleDataChange('toastStatus', 'error');
                                    clearTimeout(timeout);
                                    timeout = setTimeout(() => {
                                      handleDataChange('toastFlag', false);
                                    }, 1000);
                                  }
                                }}
                              >
                                Add
                              </Button>
                            ) : (
                              <Stack direction={'column'} alignItems={'center'}>
                                <Button
                                  onClick={() => {
                                    const dt = progfiles;
                                    handleProgrammaticPodcast(dt, i, 'update');
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  style={{ flex: 1 }}
                                  onClick={() => {
                                    handleDataChange('op', 'propodcast');
                                    handleDataChange('deletedata', fl.id);
                                    handleDataChange('deletenum', i);
                                    handleDataChange('type', 'other');
                                    handleDataChange('openDialog', true);
                                  }}
                                >
                                  <Iconify icon="eva:trash-2-outline" />
                                </Button>
                              </Stack>
                            )}
                          </Stack>
                        ) : (
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            style={{ marginBottom: '1rem', marginTop: '1rem' }}
                          >
                            <a style={{ flex: 15 }} href={fl.link}>
                              {fl.title}
                            </a>
                            {isAdmin && (
                              <Button
                                onClick={() => {
                                  const dt = progfiles;
                                  dt[i].isEdit = true;
                                  setprogfiles([...dt]);
                                }}
                              >
                                <Iconify icon="eva:edit-fill" />
                              </Button>
                            )}
                          </Stack>
                        )}
                        <hr />
                      </>
                    ))
                  ) : (
                    <Typography>No Programmatic Files found!!</Typography>
                  )}
                </>
              ) : null}
            </TabPanel>
            {data.transactionType_Programmatic === true && (
              <TableContainer style={{ display: 'none' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle1" ref={tableRef} size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCellDesign>Title</TableCellDesign>
                      <TableCellDesign>Link</TableCellDesign>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {progfiles.map((fl, i) => (
                      <TableRow>
                        <TableCellDesign>{fl.title}</TableCellDesign>
                        <TableCellDesign>{fl.link}</TableCellDesign>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {/* Ad Read Type, Position and CPM */}
            <TabPanel value={tab} index={5}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Ad Read Type:
              </Typography>
              <Table sx={{ width: 1270 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ad Read Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Client CPM</TableCell>
                    <TableCell>Publisher CPM</TableCell>
                    <TableCell>Minimum Buy Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Host Read</h3>
                    </TableCell>
                    <TableCell>
                      {data.adType_HostRead === null ? (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={networkData.adType_HostRead}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_HostRead', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                        </>
                      ) : (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={data.adType_HostRead}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_HostRead', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_HostRead === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.cpmToClient_HostRead === null ? '' : networkData.cpmToClient_HostRead}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_HostRead', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_HostRead === null ? '' : data.cpmToClient_HostRead}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_HostRead', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_HostRead === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_HostRead === null ? '' : networkData.cpmToPublisher_HostRead
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_HostRead', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToPublisher_HostRead === null ? '' : data.cpmToPublisher_HostRead}
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_HostRead', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.adType_HostRead_MBN === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.adType_HostRead_MBN === null ? '' : networkData.adType_HostRead_MBN}
                            onChange={(e) => {
                              handleDataChange('adType_HostRead_MBN', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adType_HostRead_MBN === null ? '' : data.adType_HostRead_MBN}
                            onChange={(e) => {
                              handleDataChange('adType_HostRead_MBN', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Client Provided</h3>
                    </TableCell>
                    <TableCell>
                      {data.adType_ClientProvided === null ? (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={networkData.adType_ClientProvided}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_ClientProvided', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                        </>
                      ) : (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={data.adType_ClientProvided}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_ClientProvided', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_ClientProvided === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToClient_ClientProvided === null
                                ? ''
                                : networkData.cpmToClient_ClientProvided
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToClient_ClientProvided', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_ClientProvided === null ? '' : data.cpmToClient_ClientProvided}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_ClientProvided', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_ClientProvided === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_ClientProvided === null
                                ? ''
                                : networkData.cpmToPublisher_ClientProvided
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_ClientProvided', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              data.cpmToPublisher_ClientProvided === null ? '' : data.cpmToPublisher_ClientProvided
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_ClientProvided', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.adType_ClientProvided_MBN === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.adType_ClientProvided_MBN === null
                                ? ''
                                : networkData.adType_ClientProvided_MBN
                            }
                            onChange={(e) => {
                              handleDataChange('adType_ClientProvided_MBN', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adType_ClientProvided_MBN === null ? '' : data.adType_ClientProvided_MBN}
                            onChange={(e) => {
                              handleDataChange('adType_ClientProvided_MBN', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Network Read</h3>
                    </TableCell>
                    <TableCell>
                      {data.adType_NetworkRead === null ? (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={networkData.adType_NetworkRead}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_NetworkRead', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                        </>
                      ) : (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={data.adType_NetworkRead}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_NetworkRead', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_NetworkRead === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToClient_NetworkRead === null ? '' : networkData.cpmToClient_NetworkRead
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToClient_NetworkRead', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_NetworkRead === null ? '' : data.cpmToClient_NetworkRead}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_NetworkRead', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_NetworkRead === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_NetworkRead === null
                                ? ''
                                : networkData.cpmToPublisher_NetworkRead
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_NetworkRead', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              data.cpmToPublisher_NetworkRead === null ? '' : networkData.cpmToPublisher_NetworkRead
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_NetworkRead', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.adType_NetworkRead_MBN === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.adType_NetworkRead_MBN === null ? '' : networkData.adType_NetworkRead_MBN
                            }
                            onChange={(e) => {
                              handleDataChange('adType_NetworkRead_MBN', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adType_NetworkRead_MBN === null ? '' : data.adType_NetworkRead_MBN}
                            onChange={(e) => {
                              handleDataChange('adType_NetworkRead_MBN', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Endorsement</h3>
                    </TableCell>
                    <TableCell>
                      {data.adType_Endorsement === null ? (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={networkData.adType_Endorsement}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_Endorsement', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                        </>
                      ) : (
                        <>
                          <ToggleButtonGroup
                            color="primary"
                            value={data.adType_Endorsement}
                            exclusive
                            aria-label="Platform"
                            onChange={(e, val) => {
                              handleDataChange('adType_Endorsement', val);
                            }}
                            style={{ marginLeft: '1rem' }}
                          >
                            <ToggleButton value={null}>Not set</ToggleButton>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                          </ToggleButtonGroup>
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_Endorsement === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToClient_Endorsement === null ? '' : networkData.cpmToClient_Endorsement
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToClient_Endorsement', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_Endorsement === null ? '' : data.cpmToClient_Endorsement}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_Endorsement', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_Endorsement === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_Endorsement === null
                                ? ''
                                : networkData.cpmToPublisher_Endorsement
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_Endorsement', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToPublisher_Endorsement === null ? '' : data.cpmToPublisher_Endorsement}
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_Endorsement', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.adType_Endorsement_MBN === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.adType_Endorsement_MBN === null ? '' : networkData.adType_Endorsement_MBN
                            }
                            onChange={(e) => {
                              handleDataChange('adType_Endorsement_MBN', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adType_Endorsement_MBN === null ? '' : data.adType_Endorsement_MBN}
                            onChange={(e) => {
                              handleDataChange('adType_Endorsement_MBN', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Ad Position:
              </Typography>
              <Table sx={{ width: 1030, marginBottom: '2rem' }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ad Position</TableCell>
                    <TableCell>Number of Impressions</TableCell>
                    <TableCell>Client CPM</TableCell>
                    <TableCell>Publisher CPM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Pre</h3>
                    </TableCell>
                    <TableCell>
                      {data.adPosition_Pre === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.adPosition_Pre === null ? '' : networkData.adPosition_Pre}
                            onChange={(e) => {
                              handleDataChange('adPosition_Pre', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adPosition_Pre === null ? '' : data.adPosition_Pre}
                            onChange={(e) => {
                              handleDataChange('adPosition_Pre', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_PreRoll === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.cpmToClient_PreRoll === null ? '' : networkData.cpmToClient_PreRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_PreRoll', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_PreRoll === null ? '' : data.cpmToClient_PreRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_PreRoll', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_PreRoll === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_PreRoll === null ? '' : networkData.cpmToPublisher_PreRoll
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_PreRoll', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToPublisher_PreRoll === null ? '' : data.cpmToPublisher_PreRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_PreRoll', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Mid</h3>
                    </TableCell>
                    <TableCell>
                      {data.adPosition_Mid === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.adPosition_Mid === null ? '' : networkData.adPosition_Mid}
                            onChange={(e) => {
                              handleDataChange('adPosition_Mid', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adPosition_Mid === null ? '' : data.adPosition_Mid}
                            onChange={(e) => {
                              handleDataChange('adPosition_Mid', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_MidRoll === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.cpmToClient_MidRoll === null ? '' : networkData.cpmToClient_MidRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_MidRoll', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_MidRoll === null ? '' : data.cpmToClient_MidRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_MidRoll', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_MidRoll === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_MidRoll === null ? '' : networkData.cpmToPublisher_MidRoll
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_MidRoll', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToPublisher_MidRoll === null ? '' : data.cpmToPublisher_MidRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_MidRoll', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Post</h3>
                    </TableCell>
                    <TableCell>
                      {data.adPosition_Post === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.adPosition_Post === null ? '' : networkData.adPosition_Post}
                            onChange={(e) => {
                              handleDataChange('adPosition_Post', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.adPosition_Post === null ? '' : data.adPosition_Post}
                            onChange={(e) => {
                              handleDataChange('adPosition_Post', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToClient_PostRoll === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={networkData.cpmToClient_PostRoll === null ? '' : networkData.cpmToClient_PostRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_PostRoll', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToClient_PostRoll === null ? '' : data.cpmToClient_PostRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToClient_PostRoll', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.cpmToPublisher_PostRoll === null ? (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={
                              networkData.cpmToPublisher_PostRoll === null ? '' : networkData.cpmToPublisher_PostRoll
                            }
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_PostRoll', e.target.value);
                            }}
                          />
                          {isAdmin && (
                            <Iconify
                              icon="octicon:dot-fill-24"
                              className="dot-color"
                              sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <TextField
                            label=""
                            onWheel={(e) => document.activeElement.blur()}
                            type="number"
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            value={data.cpmToPublisher_PostRoll === null ? '' : data.cpmToPublisher_PostRoll}
                            onChange={(e) => {
                              handleDataChange('cpmToPublisher_PostRoll', e.target.value);
                            }}
                            placeholder=""
                          />
                          <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Stack direction="column">
                {data.cpm === null ? (
                  <Stack direction="row">
                    <TextField
                      label="CPM"
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      value={networkData.cpm === null ? '' : networkData.cpm}
                      onChange={(e) => {
                        handleDataChange('cpm', e.target.value);
                      }}
                    />
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </Stack>
                ) : (
                  <Stack direction="row">
                    <TextField
                      label="CPM"
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      value={data.cpm === null ? '' : data.cpm}
                      onChange={(e) => {
                        handleDataChange('cpm', e.target.value);
                      }}
                      placeholder=""
                    />
                    <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                  </Stack>
                )}
                {data.customCPM === null ? (
                  <Stack direction="row">
                    <TextField
                      label="Custom CPM"
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      value={networkData.customCPM === null ? '' : networkData.customCPM}
                      onChange={(e) => {
                        handleDataChange('customCPM', e.target.value);
                      }}
                    />
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </Stack>
                ) : (
                  <Stack direction="row">
                    <TextField
                      label="Custom CPM"
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                      value={data.customCPM === null ? '' : data.customCPM}
                      onChange={(e) => {
                        handleDataChange('customCPM', e.target.value);
                      }}
                      placeholder=""
                    />
                    <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                  </Stack>
                )}
                {data.cpmNotes === null ? (
                  <Stack direction="row">
                    <TextField
                      label="CPM Notes"
                      multiline
                      rows={4}
                      style={{ width: '700px', marginTop: '1rem' }}
                      value={networkData.cpmNotes === null ? '' : networkData.cpmNotes}
                      onChange={(e) => {
                        handleDataChange('cpmNotes', e.target.value);
                      }}
                    />
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </Stack>
                ) : (
                  <Stack direction="row">
                    <TextField
                      label="CPM Notes"
                      multiline
                      rows={4}
                      style={{ width: '700px', marginTop: '1rem' }}
                      value={data.cpmNotes === null ? '' : data.cpmNotes}
                      onChange={(e) => {
                        handleDataChange('cpmNotes', e.target.value);
                      }}
                      placeholder=""
                    />
                    <Iconify icon="octicon:dot-fill-24" className="dotn-color" />
                  </Stack>
                )}
              </Stack>
            </TabPanel>

            {/* Monthly downloads & impressions */}
            <TabPanel value={tab} index={6} style={{ marginBottom: '6rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Monthly Downloads & Impressions
              </Typography>
              <Table sx={{ minWidth: 1050 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Region</TableCell>
                    <TableCell>Downloads</TableCell>
                    <TableCell>Impressions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regions.map((rg, i) => (
                    <TableRow>
                      <TableCell>
                        <h3>{rg.name}</h3>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label=""
                          type="number"
                          style={{ marginBottom: '1rem' }}
                          value={data[`monthlyDownloads_${rg.id}`] === null ? '' : data[`monthlyDownloads_${rg.id}`]}
                          onChange={(e) => {
                            handleDataChange(`monthlyDownloads_${rg.id}`, e.target.value);
                          }}
                          onWheel={(e) => document.activeElement.blur()}
                          placeholder=""
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label=""
                          type="number"
                          style={{ marginBottom: '1rem' }}
                          value={
                            data[`monthlyImpressions_${rg.id}`] === null ? '' : data[`monthlyImpressions_${rg.id}`]
                          }
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => {
                            handleDataChange(`monthlyImpressions_${rg.id}`, e.target.value);
                          }}
                          placeholder=""
                        />
                        {(data[`monthlyImpressions_${rg.id}`] === null || data[`monthlyImpressions_${rg.id}`] === 0) &&
                          data[`monthlyDownloads_${rg.id}`] > 0 && (
                            <>Expected Impression = {data[`monthlyDownloads_${rg.id}`] * 4}</>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabPanel>

            {/* Ad Position */}
            {/* <TabPanel value={tab} index={7}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Ad Position:
              </Typography>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ad Position</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Client CPM</TableCell>
                    <TableCell>Publisher CPM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Pre</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.adPosition_Pre}
                        onChange={(e) => {
                          handleDataChange('adPosition_Pre', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToClient_PreRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_PreRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToPublisher_PreRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_PreRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Mid</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.adPosition_Mid}
                        onChange={(e) => {
                          handleDataChange('adPosition_Mid', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToClient_MidRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_MidRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToPublisher_MidRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_MidRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Post</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.adPosition_Post}
                        onChange={(e) => {
                          handleDataChange('adPosition_Post', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToClient_PostRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_PostRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToPublisher_PostRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_PostRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TabPanel> */}

            {/* CPM */}
            {/* <TabPanel value={tab} index={8}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                CPM:
              </Typography>
              <TextField
                fullWidth
                label="CPM Note"
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.cpmNotes}
                onChange={(e) => {
                  handleDataChange('cpmNotes', e.target.value);
                }}
                placeholder=""
              />
            </TabPanel> */}

            {/* Restrictions */}
            <TabPanel value={tab} index={7}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Restrictions:
              </Typography>
              <Stack direction={'column'} style={{ marginBottom: '1rem' }}>
                {data.exclusionsNotes === null ? (
                  <div>
                    <TextField
                      label="Exclusion Notes"
                      multiline
                      rows={4}
                      style={{ width: '700px', marginBottom: '2rem' }}
                      value={networkData.exclusionsNotes === null ? '' : networkData.exclusionsNotes}
                      onChange={(e) => {
                        handleDataChange('exclusionsNotes', e.target.value);
                      }}
                    />
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </div>
                ) : (
                  <TextField
                    label="Exclusion Notes"
                    multiline
                    rows={4}
                    style={{ width: '700px', marginBottom: '2rem' }}
                    value={data.exclusionsNotes === null ? '' : data.exclusionsNotes}
                    onChange={(e) => {
                      handleDataChange('exclusionsNotes', e.target.value);
                    }}
                    placeholder=""
                  />
                )}
                {data.frequencyCappingNotes === null ? (
                  <div>
                    <TextField
                      label="Frequency Capping Notes"
                      multiline
                      rows={4}
                      style={{ width: '700px', marginTop: '1rem', marginBottom: '1rem' }}
                      value={networkData.frequencyCappingNotes === null ? '' : networkData.frequencyCappingNotes}
                      onChange={(e) => {
                        handleDataChange('frequencyCappingNotes', e.target.value);
                      }}
                    />
                    {isAdmin && <Iconify icon="octicon:dot-fill-24" className="dot-color" />}
                  </div>
                ) : (
                  <div>
                    <TextField
                      label="Frequency Capping Notes"
                      multiline
                      rows={4}
                      style={{ width: '700px', marginBottom: '1rem' }}
                      value={data.frequencyCappingNotes === null ? '' : data.frequencyCappingNotes}
                      onChange={(e) => {
                        handleDataChange('frequencyCappingNotes', e.target.value);
                      }}
                      placeholder=""
                    />
                  </div>
                )}
              </Stack>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Ad Exclusions:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Alcohol </Typography>
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.alcohol}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('alcohol', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                  {isAdmin && data.alcohol === networkData.alcohol && (
                    <Iconify icon="octicon:dot-fill-24" className="dot-color" />
                  )}
                </>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Cannabis </Typography>
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.cannabis}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('cannabis', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                  {isAdmin && data.cannabis === networkData.cannabis && (
                    <Iconify icon="octicon:dot-fill-24" className="dot-color" />
                  )}
                </>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Profane/Explicit content </Typography>
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.explicitContent}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('explicitContent', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                  {isAdmin && data.explicitContent === networkData.explicitContent && (
                    <Iconify icon="octicon:dot-fill-24" className="dot-color" />
                  )}
                </>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Gambling </Typography>
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.gambling}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('gambling', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                  {isAdmin && data.gambling === networkData.gambling && (
                    <Iconify icon="octicon:dot-fill-24" className="dot-color" />
                  )}
                </>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Pharma </Typography>
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.pharma}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('pharma', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                  {isAdmin && data.pharma === networkData.pharma && (
                    <Iconify icon="octicon:dot-fill-24" className="dot-color" />
                  )}
                </>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Politics </Typography>
                <>
                  <ToggleButtonGroup
                    color="primary"
                    value={data.politics}
                    exclusive
                    aria-label="Platform"
                    onChange={(e, val) => {
                      handleDataChange('politics', val);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    <ToggleButton value={null}>Not set</ToggleButton>
                    <ToggleButton value={true}>Yes</ToggleButton>
                    <ToggleButton value={false}>No</ToggleButton>
                  </ToggleButtonGroup>
                  {isAdmin && data.politics === networkData.politics && (
                    <Iconify icon="octicon:dot-fill-24" className="dot-color" />
                  )}
                </>
              </Stack>
              {/* <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <b style={{ marginRight: '2rem' }}>Explicit Content:</b>
                <ToggleButtonGroup
                  color="primary"
                  value={false}
                  exclusive
                  aria-label="Platform"
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack> */}
            </TabPanel>
            <Snackbar open={data.toastFlag} TransitionComponent={SlideTransition}>
              <Alert severity={data.toastStatus}>{data.toastMsg}</Alert>
            </Snackbar>
          </Card>
        </Container>
      )}
    </Page>
  );
}
