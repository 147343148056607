/* eslint-disable react/jsx-boolean-value */

import React, { useEffect, useState, useRef } from 'react';
import * as xlsx from 'xlsx/xlsx.mjs';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import {
  Alert,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  styled,
  Stack,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Avatar,
  Divider,
} from '@mui/material';
import Page from '../components/Page';
import { getAllNetworks } from '../services/networkServices';
import { addPodcast, bulkUpdatePodcast, getPodcastByNetworkId } from '../services/podcastServices';
import { getThirdPartyAPIPodcast, getThirdPartyAPIPodcastByTitle } from '../services/thirdPartyApiService';
import Iconify from '../components/Iconify';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function ImportNetwork() {
  let timeout = 3000;
  const isAdmin = JSON.parse(localStorage.getItem('userData')).isAdmin;
  if (!isAdmin) {
    const url = window.location.toString();
    // eslint-disable-next-line no-restricted-globals
    window.location = url.replace(location.pathname, `/dashboard/networks`);
  }
  const tabledisplay = [
    'title',
    'author',
    'description',
    'networkId',
    'artworkUrl',
    'rssFeedUrl',
    'feedId',
    'categories',
    'language_En',
    'language_Fr',
    'geo_Canada',
    'geo_US',
    'geo_Provincial',
    'geo_Municipal',
    'canTrack_Chartable',
    'canTrack_Podsights',
    'canTrack_LoopMe',
    'canTrack_Notes',
    'transactionType_Programmatic',
    'transactionType_Direct',
    'adType_HostRead',
    'adType_ClientProvided',
    'adType_NetworkRead',
    'adType_Endorsement',
    'cpmToClient_HostRead',
    'cpmToClient_ClientProvided',
    'cpmToClient_NetworkRead',
    'cpmToClient_Endorsement',
    'cpmToPublisher_HostRead',
    'cpmToPublisher_ClientProvided',
    'cpmToPublisher_NetworkRead',
    'cpmToPublisher_Endorsement',
    'adType_HostRead_MBN',
    'adType_ClientProvided_MBN',
    'adType_NetworkRead_MBN',
    'adType_Endorsement_MBN',
    'cpmToClient_PreRoll',
    'cpmToClient_MidRoll',
    'cpmToClient_PostRoll',
    'cpmToClient_Collection',
    'cpmToPublisher_PreRoll',
    'cpmToPublisher_MidRoll',
    'cpmToPublisher_PostRoll',
    'cpmToPublisher_Collection',
    'adPosition_Pre',
    'adPosition_Mid',
    'adPosition_Post',
    'adPosition_Total',
    'cpm',
    'customCPM',
    'cpmNotes',
    'monthlyDownloads_CA',
    'monthlyDownloads_US',
    'monthlyDownloads_AU',
    'monthlyDownloads_UK',
    'monthlyDownloads_BC',
    'monthlyDownloads_AB',
    'monthlyDownloads_SK',
    'monthlyDownloads_MA',
    'monthlyDownloads_ON',
    'monthlyDownloads_QB',
    'monthlyDownloads_AT',
    'monthlyImpressions_CA',
    'monthlyImpressions_US',
    'monthlyImpressions_AU',
    'monthlyImpressions_UK',
    'monthlyImpressions_BC',
    'monthlyImpressions_AB',
    'monthlyImpressions_SK',
    'monthlyImpressions_MA',
    'monthlyImpressions_ON',
    'monthlyImpressions_QB',
    'monthlyImpressions_AT',
    'exclusionsNotes',
    'frequencyCappingNotes',
    'alcohol',
    'cannabis',
    'explicitContent',
    'gambling',
    'pharma',
    'politics',
    'iTunesID',
    'podcastIndexFeedRaw',
    'itunesFeedRaw',
    'rssContentRaw',
    'collection',
    'podcastNotes',
    'files',
  ];
  const tablecolumn = [
    'tpX_Id',
    'title',
    'artworkUrl',
    'description',
    'podcastNotes',
    'language_En',
    'language_Fr',
    'geo_Canada',
    'geo_US',
    'geo_Provincial',
    'geo_Municipal',
    'canTrack_Chartable',
    'canTrack_Podsights',
    'canTrack_LoopMe',
    'canTrack_Notes',
    'transactionType_Programmatic',
    'transactionType_Direct',
    'adType_HostRead',
    'cpmToClient_HostRead',
    'cpmToPublisher_HostRead',
    'adType_HostRead_MBN',
    'adType_ClientProvided',
    'cpmToClient_ClientProvided',
    'cpmToPublisher_ClientProvided',
    'adType_ClientProvided_MBN',
    'adType_NetworkRead',
    'cpmToClient_NetworkRead',
    'cpmToPublisher_NetworkRead',
    'adType_NetworkRead_MBN',
    'adType_Endorsement',
    'cpmToClient_Endorsement',
    'cpmToPublisher_Endorsement',
    'adType_Endorsement_MBN',
    'adPosition_Pre',
    'cpmToClient_PreRoll',
    'cpmToPublisher_PreRoll',
    'adPosition_Mid',
    'cpmToClient_MidRoll',
    'cpmToPublisher_MidRoll',
    'adPosition_Post',
    'cpmToClient_PostRoll',
    'cpmToPublisher_PostRoll',
    'cpm',
    'customCPM',
    'cpmNotes',
    'monthlyDownloads_CA',
    'monthlyDownloads_BC',
    'monthlyDownloads_AB',
    'monthlyDownloads_SK',
    'monthlyDownloads_MA',
    'monthlyDownloads_ON',
    'monthlyDownloads_QB',
    'monthlyDownloads_AT',
    'monthlyDownloads_US',
    'monthlyDownloads_AU',
    'monthlyDownloads_UK',
    'monthlyImpressions_CA',
    'monthlyImpressions_BC',
    'monthlyImpressions_AB',
    'monthlyImpressions_SK',
    'monthlyImpressions_MA',
    'monthlyImpressions_ON',
    'monthlyImpressions_QB',
    'monthlyImpressions_AT',
    'monthlyImpressions_US',
    'monthlyImpressions_AU',
    'monthlyImpressions_UK',
    'exclusionsNotes',
    'frequencyCappingNotes',
    'alcohol',
    'cannabis',
    'explicitContent',
    'gambling',
    'pharma',
    'politics',
    'networkId',
    'categories',
    'rssFeedUrl',
    'feedId',
    'author',
    'iTunesID',
  ];
  const forToggle = [
    'language_En',
    'language_Fr',
    'geo_Canada',
    'geo_US',
    'geo_Provincial',
    'geo_Municipal',
    'canTrack_Chartable',
    'canTrack_Podsights',
    'canTrack_LoopMe',
    'transactionType_Programmatic',
    'transactionType_Direct',
    'adType_HostRead',
    'adType_ClientProvided',
    'adType_NetworkRead',
    'adType_Endorsement',
    'alcohol',
    'cannabis',
    'explicitContent',
    'gambling',
    'pharma',
    'politics',
  ];
  const [oldData, setOldData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [podcastData, setPodcastData] = useState([]);
  const [thirdPartyData, setThirdPartyData] = useState({});
  const [data, setData] = useState([]);
  const [networkNames, setNetworkNames] = useState([]);
  const [networkId, setNetworkId] = useState(0);
  const tableRef = useRef(null);

  const TableCellDesign = styled(TableCell)`
    font-size: 0.75rem;
    border: 1px solid rgba(241, 243, 244, 1);
    .multiLineEllipsis {
      width: 250px;
      height: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .artwork {
      width: 70px;
      height: 70px;
      border-radius: 15px;
    }
    &:hover .multiLineEllipsis {
      width: 250px;
      height: 70px;
      overflow: auto;
      white-space: normal;
    }
  `;

  useEffect(() => {
    getAllNetworks().then((res) => {
      if (res.success) {
        const data = res.data.sort((a, b) =>
          // eslint-disable-next-line no-nested-ternary
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
        );
        setNetworkNames([...data]);
      }
    });
  }, []);

  useEffect(() => {
    if (networkId > 0) {
      handleDataChange('uploadButton', 'none');
      setOldData([...[]]);
      setNewData([...[]]);
      getPodcastByNetworkId(networkId).then((res) => {
        if (res.success) {
          const data = res.data.sort((a, b) =>
            // eslint-disable-next-line no-nested-ternary
            a.title.toLowerCase() > b.title.toLowerCase() ? 1 : b.title.toLowerCase() > a.title.toLowerCase() ? -1 : 0
          );
          data.forEach((dt, i) => {
            forToggle.forEach((pd) => {
              if (dt[pd] === true) {
                data[i][pd] = 'Yes';
              } else if (dt[pd] === false) {
                data[i][pd] = 'No';
              } else if (dt[pd] === null) {
                data[i][pd] = 'Not Set';
              }
            });
          });
          setPodcastData([...data]);
          handleDataChange('uploadButton', 'show');
        }
      });
    }
  }, [networkId]);

  useEffect(() => {
    if (oldData.length > 0) {
      bulkUpdatePodcast(oldData, networkId).then((res) => {
        if (res.success) {
          setOldData([...[]]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Data Updated Successfully');
          handleDataChange('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    }
  }, [oldData]);

  const handleDataChange = (fld, val) => {
    data[fld] = val;
    setData({ ...data });
  };
  const handleDataUpdate = (fld, id, index, key) => {
    const tpData = thirdPartyData;
    const temp = thirdPartyData[fld].filter((e) => {
      return e.id === id;
    });
    tpData[temp[0].title] = tpData[newData[index].title];
    if (temp[0].title !== newData[index].title) {
      delete tpData[newData[index].title];
    }
    newData[index].title = temp[0].title;
    newData[index].description = temp[0].description;
    newData[index].author = temp[0].author;
    newData[index].artworkUrl = temp[0].artwork;
    newData[index].feedId = temp[0].id;
    if (temp[0].itunesId !== null) {
      newData[index].iTunesID = temp[0].itunesId.toString();
    }
    newData[index].languages = temp[0].language;
    newData[index].rssFeedUrl = temp[0].originalUrl;
    const tempcat = Object.values(temp[0].categories).toString().split(',');
    tempcat.forEach((cat) => {
      if (cat === 'Fashion') {
        const temp1 = tempcat.indexOf('Fashion');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Beauty');
        tempcat.splice(temp2, 1, 'Fashion & Beauty');
      } else if (cat === 'Pets') {
        const temp1 = tempcat.indexOf('Pets');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Animals');
        tempcat.splice(temp2, 1, 'Pets & Animals');
      } else if (cat === 'Animation') {
        const temp1 = tempcat.indexOf('Animation');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Manga');
        tempcat.splice(temp2, 1, 'Animation & Manga');
      } else if (cat === 'Home') {
        const temp1 = tempcat.indexOf('Home');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Garden');
        tempcat.splice(temp2, 1, 'Home & Garden');
      } else if (cat === 'Society') {
        const temp1 = tempcat.indexOf('Society');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Culture');
        tempcat.splice(temp2, 1, 'Society & Culture');
      } else if (cat === 'TV') {
        const temp1 = tempcat.indexOf('TV');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Film');
        tempcat.splice(temp2, 1, 'TV & Film');
      } else if (cat === 'Religion') {
        const temp1 = tempcat.indexOf('Religion');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Spirituality');
        tempcat.splice(temp2, 1, 'Religion & Spirituality');
      } else if (cat === 'Kids') {
        const temp1 = tempcat.indexOf('Kids');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Family');
        tempcat.splice(temp2, 1, 'Kids & Family');
      } else if (cat === 'Health') {
        const temp1 = tempcat.indexOf('Health');
        tempcat.splice(temp1, 1);
        const temp2 = tempcat.indexOf('Fitness');
        tempcat.splice(temp2, 1, 'Health & Fitness');
      }
    });
    newData[index].categories = Object.values(tempcat).toString();
    setThirdPartyData({ ...tpData });
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const oldData = [];
      const newData = [];
      const arr = {};
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setData(json);
        handleDataChange('buttonView', 'show');
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Data Imported Successfully');
        handleDataChange('toastStatus', 'success');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 4000);
        // eslint-disable-next-line no-plusplus
        for (let a = 0; a < json.length; a++) {
          if (json[a].tpX_Id === undefined) {
            newData.push(json[a]);
            getThirdPartyAPIPodcast(json[a].title).then((res) => {
              const termData = res;
              getThirdPartyAPIPodcastByTitle(json[a].title).then((res1) => {
                arr[json[a].title] = res1.concat(termData);
                arr[json[a].title] = arr[json[a].title].filter(
                  (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
                );
                setThirdPartyData({ ...arr });
              });
            });
          } else {
            forToggle.forEach((pd) => {
              if (json[a][pd] === 'Yes' || json[a][pd] === 'yes') {
                json[a][pd] = true;
              } else if (json[a][pd] === 'No' || json[a][pd] === 'no') {
                json[a][pd] = false;
              } else if (json[a][pd] === 'Not Set' || json[a][pd] === 'not set') {
                json[a][pd] = null;
              }
            });
            if (json[a].iTunesID !== undefined) {
              json[a].iTunesID = json[a].iTunesID.toString();
            }
            oldData.push(json[a]);
          }
        }
        setOldData([...oldData]);
        setNewData([...newData]);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const handleAddPodcast = (data, index) => {
    forToggle.forEach((pd) => {
      if (data[pd] === 'Yes' || data[pd] === 'yes') {
        data[pd] = true;
      } else if (data[pd] === 'No' || data[pd] === 'no') {
        data[pd] = false;
      } else if (data[pd] === 'Not Set' || data[pd] === 'no') {
        data[pd] = null;
      }
    });
    addPodcast(data).then((res) => {
      if (res.success) {
        // podcast added toast
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', `Podcast ${data.title} added Successfully`);
        handleDataChange('toastStatus', 'success');
        newData.splice(index, 1);
        setNewData([...newData]);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
      } else if (res.status === 403) {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', "You don't have admin rights");
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Something went wrong!');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
      }
    });
  };
  return (
    <Page title="Dashboard">
      <Container maxWidth="xxl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Import
          </Typography>
        </Stack>
        <Card spacing={3} style={{ padding: '2rem' }}>
          <Stack direction="row">
            <FormControl sx={{ minWidth: 220 }}>
              <InputLabel>Network Name</InputLabel>
              <Select
                label="Network Name"
                onChange={(e) => {
                  setNetworkId(e.target.value);
                  handleDataChange('date', new Date().toLocaleDateString());
                }}
              >
                {networkNames.map((e) => (
                  <MenuItem key={e.id} value={e.id} onClick={() => handleDataChange('networkName', e.name)}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          {data.uploadButton === 'show' ? (
            <Stack direction="row" style={{ marginTop: '2rem', marginBottom: '1rem' }}>
              <Button variant="contained" component="label" sx={{ minWidth: 220 }}>
                <Iconify icon="ic:round-file-upload" width={24} height={24} />
                Upload File
                <input type="file" onChange={readUploadFile} hidden />
              </Button>
              <DownloadTableExcel
                filename={`${data.networkName} ${data.date}`}
                sheet="podcasts"
                currentTableRef={tableRef.current}
              >
                <Button disableRipple color="inherit" endIcon={<Iconify icon="material-symbols:download-rounded" />}>
                  Download Podcast Import Template
                </Button>
              </DownloadTableExcel>
            </Stack>
          ) : null}

          {newData.length > 0 ? (
            <>
              <TableContainer>
                <Table sx={{ minWidth: 1000, marginTop: '1rem' }} aria-labelledby="tableTitle" size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCellDesign />
                      <TableCellDesign />
                      {tabledisplay.map((column) => (
                        <TableCellDesign key={column}>{column}</TableCellDesign>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* eslint-disable-next-line no-return-assign */}
                    {newData.map((net, i) => (
                      // for insert
                      <React.Fragment key={i}>
                        <TableRow>
                          <TableCellDesign>
                            <Button
                              onClick={() => {
                                newData[i].networkId = networkId;
                                handleAddPodcast(newData[i], i);
                              }}
                            >
                              Add
                            </Button>
                          </TableCellDesign>
                          <TableCellDesign>
                            <FormControl sx={{ width: 200 }}>
                              <InputLabel>Podcast</InputLabel>
                              <Select
                                value={net.feedId}
                                defaultValue="a"
                                label="Podcast"
                                onChange={(e) => {
                                  handleDataUpdate(net.title, e.target.value, i);
                                }}
                              >
                                <MenuItem value="a">No podcast selected</MenuItem>
                                {thirdPartyData[net.title] !== undefined ? (
                                  thirdPartyData[net.title].map((data) => (
                                    <MenuItem
                                      key={data.id}
                                      value={data.id}
                                      sx={{ width: 500, whiteSpace: 'normal', borderBottom: '1px dashed rgb(0, 0, 0)' }}
                                    >
                                      <Avatar
                                        sx={{
                                          width: '50px',
                                          height: '50px',
                                          borderRadius: '10px',
                                          marginRight: '10px',
                                        }}
                                        src={data.artwork}
                                        alt="artwork"
                                      />
                                      {''}|
                                      <span style={{ paddingLeft: '10px', paddingRight: '10px', width: '225px' }}>
                                        {data.title}
                                      </span>
                                      |
                                      <span style={{ paddingLeft: '10px', paddingRight: '10px', width: '225px' }}>
                                        {data.author}
                                      </span>
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="a">none found</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </TableCellDesign>
                          {tabledisplay.map((column) => (
                            <TableCellDesign key={column}>
                              {column === 'artworkUrl' ? (
                                <img src={net[column]} alt={net.title} className="artwork" />
                              ) : (
                                <div className="multiLineEllipsis">{net[column]}</div>
                              )}
                            </TableCellDesign>
                          ))}
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : null}
          <TableContainer style={{ display: 'none' }}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" ref={tableRef}>
              <TableHead>
                <TableRow>
                  {tablecolumn.length > 0
                    ? tablecolumn.map((column) => <TableCellDesign key={column}>{column}</TableCellDesign>)
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {podcastData.map((data) => (
                  <TableRow key={data.id}>
                    {tablecolumn.map((dt) => (
                      <TableCellDesign key={dt}>{data[dt]}</TableCellDesign>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Snackbar open={data.toastFlag} TransitionComponent={SlideTransition}>
          <Alert severity={data.toastStatus}>{data.toastMsg}</Alert>
        </Snackbar>
      </Container>
    </Page>
  );
}
