import { useState, useEffect } from 'react';
import '../../../App.css';
import PropTypes from 'prop-types';
// material
import {
  Button,
  TextField,
  Slide,
  Snackbar,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
  Autocomplete,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { addNetWork } from '../../../services/networkServices';
import { addPodcast } from '../../../services/podcastServices';
import { getThirdPartyAPIPodcast, getThirdPartyAPIPodcastByTitle } from '../../../services/thirdPartyApiService';

AddNetworkDialog.propTypes = {
  isOpen: PropTypes.bool,
  popUp: PropTypes.func,
  isPodcast: PropTypes.bool,
  networkNames: PropTypes.array,
};
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
export default function AddNetworkDialog({ popUp, isOpen, isPodcast, networkNames }) {
  let timeout = 2000;

  const [nid, setnid] = useState('');
  const [name, setName] = useState('');
  const [toast, setToast] = useState([]);
  const [tpd, settpd] = useState([]);
  const [temp, settemp] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (name.length > 2) {
      getThirdPartyAPIPodcast(name).then((res) => {
        const termData = res;
        getThirdPartyAPIPodcastByTitle(name).then((res1) => {
          let temp = res1.concat(termData);
          temp = temp.filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id));
          settpd([...temp]);
        });
      });
    }
  }, [name]);
  const handleChange = () => {
    popUp(popUp);
  };

  const handleToast = (fld, val) => {
    toast[fld] = val;
    setToast({ ...toast });
  };

  const handleAdd = () => {
    setIsLoading(true);
    const data = {};
    if (isPodcast) {
      data.networkId = nid;
      data.title = temp.title;
      const id = tpd.findIndex((elem) => elem.id === temp.id);
      data.title = tpd[id].title;
      data.description = tpd[id].description;
      data.author = tpd[id].author;
      data.artworkUrl = tpd[id].artwork;
      data.feedId = tpd[id].id;
      if (tpd[id].itunesId !== null) {
        data.iTunesID = tpd[id].itunesId.toString();
      }
      data.languages = tpd[id].language;
      data.rssFeedUrl = tpd[id].originalUrl;
      const tempcat = Object.values(tpd[id].categories).toString().split(',');
      tempcat.forEach((cat) => {
        if (cat === 'Fashion') {
          const temp1 = tempcat.indexOf('Fashion');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Beauty');
          tempcat.splice(temp2, 1, 'Fashion & Beauty');
        } else if (cat === 'Pets') {
          const temp1 = tempcat.indexOf('Pets');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Animals');
          tempcat.splice(temp2, 1, 'Pets & Animals');
        } else if (cat === 'Animation') {
          const temp1 = tempcat.indexOf('Animation');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Manga');
          tempcat.splice(temp2, 1, 'Animation & Manga');
        } else if (cat === 'Home') {
          const temp1 = tempcat.indexOf('Home');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Garden');
          tempcat.splice(temp2, 1, 'Home & Garden');
        } else if (cat === 'Society') {
          const temp1 = tempcat.indexOf('Society');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Culture');
          tempcat.splice(temp2, 1, 'Society & Culture');
        } else if (cat === 'Tv') {
          const temp1 = tempcat.indexOf('Tv');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Film');
          tempcat.splice(temp2, 1, 'TV & Film');
        } else if (cat === 'Religion') {
          const temp1 = tempcat.indexOf('Religion');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Spirituality');
          tempcat.splice(temp2, 1, 'Religion & Spirituality');
        } else if (cat === 'Kids') {
          const temp1 = tempcat.indexOf('Kids');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Family');
          tempcat.splice(temp2, 1, 'Kids & Family');
        } else if (cat === 'Health') {
          const temp1 = tempcat.indexOf('Health');
          tempcat.splice(temp1, 1);
          const temp2 = tempcat.indexOf('Fitness');
          tempcat.splice(temp2, 1, 'Health & Fitness');
        }
      });
      data.categories = tempcat.toString();
      if (nid !== '') {
        addPodcast(data).then((res) => {
          if (res.success) {
            setIsLoading(false);
            handleToast('toastFlag', true);
            handleToast('toastMsg', `${name} Added Successfully`);
            handleToast('toastStatus', 'success');
            handleChange();
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              handleToast('toastFlag', false);
              const url = window.location.toString();
              // eslint-disable-next-line no-restricted-globals
              window.location = url.concat(`/${res.data.tpX_Id}`);
            }, 1500);
          } else if (res.status === 403) {
            setIsLoading(false);
            handleToast('toastFlag', true);
            handleToast('toastMsg', "You don't have admin rights");
            handleToast('toastStatus', 'error');
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              handleToast('toastFlag', false);
            }, 2000);
          } else {
            setIsLoading(false);
            handleToast('toastFlag', true);
            handleToast('toastMsg', 'Something went wrong!');
            handleToast('toastStatus', 'error');
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              handleToast('toastFlag', false);
            }, 1000);
          }
        });
      } else {
        setIsLoading(false);
        handleToast('toastFlag', true);
        handleToast('toastMsg', 'Please select one network ');
        handleToast('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleToast('toastFlag', false);
        }, 1500);
      }
    } else {
      addNetWork({ name }).then((res) => {
        if (res.success) {
          setIsLoading(false);
          handleToast('toastFlag', true);
          handleToast('toastMsg', `${name} Added Successfully`);
          handleToast('toastStatus', 'success');
          popUp(false);
          handleChange();
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleToast('toastFlag', false);
            const url = window.location.toString();
            // eslint-disable-next-line no-restricted-globals
            window.location = url.concat(`/${res.data.id}`);
          }, 1500);
        } else if (res.status === 403) {
          setIsLoading(false);
          handleToast('toastFlag', true);
          handleToast('toastMsg', "You don't have admin rights");
          handleToast('toastStatus', 'error');
          popUp(false);
          handleChange();
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleToast('toastFlag', false);
          }, 2000);
        } else {
          setIsLoading(false);
          handleToast('toastFlag', true);
          handleToast('toastMsg', 'Something went wrong!');
          handleToast('toastStatus', 'error');
          popUp(false);
          handleChange();
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleToast('toastFlag', false);
          }, 1000);
        }
      });
    }
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsLoading(false);
          handleChange();
        }}
      >
        <DialogContent style={{ width: '600px' }}>
          {isPodcast ? (
            <>
              <Autocomplete
                sx={{ width: 550 }}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={tpd}
                getOptionLabel={(option) => option.title}
                onChange={(e, value) => {
                  setName(value.title);
                  settemp(value);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                    <img
                      loading="lazy"
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '10px',
                        marginRight: '10px',
                      }}
                      src={option.artwork}
                      alt="artwork"
                    />
                    | {option.title} | {option.author}
                  </Box>
                )}
                renderInput={(params) => (
                  <>
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Podcast Name"
                      type="name"
                      fullWidth
                      variant="standard"
                      value={name}
                      {...params}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </>
                )}
              />
              <FormControl sx={{ width: 550, mt: '2rem' }}>
                <InputLabel>Network Name</InputLabel>
                <Select
                  value={nid}
                  label="Network Name"
                  onChange={(e) => {
                    setnid(e.target.value);
                  }}
                >
                  {networkNames.map((e, i) => (
                    <MenuItem key={i} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          ) : (
            <TextField
              autoFocus
              margin="dense"
              label="Network Name"
              type="name"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ height: '40px', width: '80px' }}
            onClick={() => {
              handleChange();
              setIsLoading(false);
            }}
          >
            Close
          </Button>
          <LoadingButton
            color="primary"
            onClick={() => handleAdd()}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<Iconify icon="eva:plus-outline" width={20} height={20} />}
            variant="outlined"
            sx={{ height: '40px', width: '80px', mr: '15px' }}
          >
            <span>Add</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* toast */}
      <Snackbar open={toast.toastFlag} TransitionComponent={SlideTransition}>
        <Alert severity={toast.toastStatus}>{toast.toastMsg}</Alert>
      </Snackbar>
    </>
  );
}
