import * as serviceContext from './serviceContext';

const { REACT_APP_API_ENDPOINT } = process.env;

console.log(REACT_APP_API_ENDPOINT); // Ensure this logs "/api"


const options = {};
const headers = new Headers();

headers.append('Access-Control-Allow-Origin', '*');
headers.append('mode', 'cors');
headers.append('Content-Type', 'application/json');

options.headers = headers;

export async function userLogin(data) {
  try {
    const response = await serviceContext.post(`${REACT_APP_API_ENDPOINT}/token`, JSON.stringify(data), options);
    const res = response.json();
    return res;
  } catch (error) {
    return error;
  }
}
