/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-object-spread */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Container,
  Stack,
  Slider,
  Typography,
  Box,
  Card,
  TextField,
  Button,
  Checkbox,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Snackbar,
  Slide,
  Alert,
  DialogActions,
  Dialog,
  DialogContent,
  InputAdornment,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  getNetworkById,
  updateNetWork,
  getFilesByNetworkId,
  addFile,
  deleteFile,
  deleteNetwork,
} from '../services/networkServices';
import { addContact, updateContact, deleteContact } from '../services/contactService';
import { bulkDeletePodcast } from '../services/podcastServices';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
export default function NetworkDetail() {
  let timeout = 3000;
  const { id } = useParams();
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem('userData')).isAdmin;

  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [podcastDelete, setPodcastDelete] = useState(new Set());
  const [tab, setTab] = useState(0);
  //   demographic
  //   const [incomevalue, setIncomeValue] = useState([0, 0]);
  //   const [agevalue, setAgeValue] = useState([0, 0]);

  const regions = [
    { id: 'CA', name: 'CA' },
    { id: 'BC', name: 'BC' },
    { id: 'AB', name: 'Alberta' },
    { id: 'SK', name: 'Saskatchewan' },
    { id: 'MA', name: 'Manitoba' },
    { id: 'ON', name: 'Ontario' },
    { id: 'QB', name: 'Quebec' },
    { id: 'AT', name: 'Atlantic Canada' },
    { id: 'US', name: 'US' },
    { id: 'AU', name: 'AU' },
    { id: 'UK', name: 'UK' },
  ];
  useEffect(() => {
    getNetworkById(id).then((res) => {
      if (res.success) {
        res.data.podcasts.sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : b.title.toLowerCase() > a.title.toLowerCase() ? -1 : 0
        );
        setData(res.data);
        // demographic
        // setIncomeValue([res.data.demoIncomeMin, res.data.demoIncomeMax]);
        // setAgeValue([res.data.demoAgeMin, res.data.demoAgeMax]);
      }
    });
    getFilesByNetworkId(id).then((res) => {
      if (res.success) {
        setFiles(res.data);
      }
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleDataChange = (fld, val) => {
    if (isAdmin) {
      data[fld] = val;
      // demographic
      // if (fld === 'demoEducationOther' && val === false) {
      //   data.demoEducationNotes = null;
      // }
      setData({ ...data });
    }
  };
  // demographic
  //   const handleChangeIncome = (newValue) => {
  //     data.demoIncomeMin = newValue[0];
  //     data.demoIncomeMax = newValue[1];
  //     setIncomeValue(newValue);
  //     setData({ ...data });
  //   };
  //   const handleChangeAge = (newValue) => {
  //     data.demoAgeMin = newValue[0];
  //     data.demoAgeMax = newValue[1];
  //     setAgeValue(newValue);
  //     setData({ ...data });
  //   };

  const handleDelete = () => {
    if (data.op === 'network') {
      deleteNetwork(id).then((res) => {
        if (res.success) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Network Deleted Successfully');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            navigate(`/dashboard/networks`, { replace: true });
          }, 1000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    } else if (data.op === 'file') {
      deleteFile(data.deletedata).then((res) => {
        if (res.success) {
          files.splice(data.deletenum, 1);
          setFiles([...files]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'File Deleted Successfully');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    } else if (data.op === 'contact') {
      deleteContact(data.deletedata).then((res) => {
        if (res.success) {
          data.contacts.splice(data.deletenum, 1);
          setFiles([...files]);
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Contact Deleted Successfully');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    }
  };
  const nllTheBlank = (data) => {
    Object.keys(data).forEach((elem) => {
      data[elem] = data[elem] === '' ? null : data[elem];
    });
    return data;
  };
  const handleNetwork = () => {
    // data
    let body = Object.assign({ ...data });
    delete body.podcasts;
    body = nllTheBlank(body);
    updateNetWork(body).then((res) => {
      if (res.success) {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Data Updated Successfully');
        handleDataChange('toastStatus', 'success');
      } else if (res.status === 403) {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', "You don't have admin rights");
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Something went wrong!');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
      }
    });
  };
  const handleFile = (data, i) => {
    addFile(id, data).then((res) => {
      if (res.success) {
        data.isEdit = false;
        data.id = res.data.id;
        setFiles([...files]);
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'File Added Successfully');
        handleDataChange('toastStatus', 'success');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else if (res.status === 403) {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', "You don't have admin rights");
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Something went wrong!');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
      }
    });
  };
  const handleContact = (dt, op, i) => {
    if (op === 'add') {
      addContact(dt.contacts[i]).then((res) => {
        if (res.success) {
          dt.contacts[i].isEdit = false;
          dt.contacts[i].isNew = false;
          dt.contacts[i].id = res.data.id;
          setData({ ...dt });
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Contact Insert Successfully');
          handleDataChange('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    } else if (op === 'save') {
      updateContact(dt.contacts[i]).then((res) => {
        if (res.success) {
          dt.contacts[i].isEdit = false;
          setData({ ...dt });
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Contact Updated Successfully');
          handleDataChange('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else if (res.status === 403) {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', "You don't have admin rights");
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 2000);
        } else {
          handleDataChange('toastFlag', true);
          handleDataChange('toastMsg', 'Something went wrong!');
          handleDataChange('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleDataChange('toastFlag', false);
          }, 1000);
        }
      });
    }
  };
  const handleBulkDeletePodcast = (arr) => {
    const temp = data.podcasts;
    bulkDeletePodcast(arr).then((res) => {
      if (res.success) {
        // eslint-disable-next-line no-restricted-syntax
        for (const id of arr) {
          temp.splice(
            temp.findIndex((elem) => elem.tpX_Id === id),
            1
          );
        }
        setPodcastDelete(new Set());
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Podcast Deleted Successfully');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('isDelete', false);
          handleDataChange('toastFlag', false);
        }, 2000);
      } else if (res.status === 403) {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', "You don't have admin rights");
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 2000);
      } else {
        handleDataChange('toastFlag', true);
        handleDataChange('toastMsg', 'Something went wrong!');
        handleDataChange('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleDataChange('toastFlag', false);
        }, 1000);
      }
    });
  };

  return (
    <Page title="Network Detail">
      {!!data.name && (
        <Container maxWidth="xxl">
          <Stack
            style={{ margin: '0 0 2rem 1rem' }}
            className="mb-2"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography
                onClick={() => {
                  navigate(`/dashboard/networks`, { replace: true });
                }}
              >
                Networks
              </Typography>
              <Typography color="text.primary">{data.name}</Typography>
            </Breadcrumbs>
            {isAdmin ? (
              <Stack direction="row">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleDataChange('op', 'network');
                    handleDataChange('type', 'other');
                    handleDataChange('openDialog', true);
                  }}
                >
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    if (
                      (data.demoEducationNotes === null && data.demoEducationOther === false) ||
                      data.demoEducationOther === null ||
                      (data.demoEducationNotes !== null && data.demoEducationOther === true)
                    ) {
                      handleNetwork();
                    } else {
                      handleDataChange('toastFlag', true);
                      handleDataChange('toastMsg', 'Please specify');
                      handleDataChange('toastStatus', 'error');
                    }
                  }}
                  variant="contained"
                  style={{ width: '100px', marginLeft: '1rem', marginRight: '1rem' }}
                >
                  Save
                </Button>
              </Stack>
            ) : null}
          </Stack>
          <Card style={{ padding: '1rem 2rem 2rem', minHeight: '40rem' }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="About" />
              <Tab label="Languages & Geo" />
              <Tab label="Podcasts" />
              <Tab label="Third-Party Tracking" />
              <Tab label="Transaction Type" />
              <Tab label="Ad Read Type, Position and CPM" />
              <Tab label="Monthly Downloads & Impressions" />
              {/* <Tab label="Ad Position" /> */}
              <Tab label="Contacts" />
              {/* <Tab label="CPM" /> */}
              <Tab label="Restrictions" />
              {/* <Tab label="Demographics" /> */}
            </Tabs>
            {/* About */}
            <TabPanel value={tab} index={0} style={{ marginBottom: '6rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                About:
              </Typography>
              <TextField
                fullWidth
                label="Network Name"
                style={{ marginBottom: '1rem' }}
                value={data.name}
                onChange={(e) => {
                  handleDataChange('name', e.target.value);
                }}
              />
              <TextField
                fullWidth
                label="Network Note"
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.networkNotes === null ? '' : data.networkNotes}
                onChange={(e) => {
                  handleDataChange('networkNotes', e.target.value);
                }}
                placeholder=""
              />
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Files:
                {isAdmin && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                      const dt = files;
                      const ct = {
                        fileName: '',
                        url: '',
                        networkId: 'id',
                        isEdit: true,
                        isNew: true,
                      };
                      dt.push(ct);
                      setFiles([...dt]);
                    }}
                  >
                    <Iconify icon="eva:plus-outline" width={24} height={24} />
                  </Button>
                )}
              </Typography>
              {files.length > 0 ? (
                files.map((fl, i) => (
                  <>
                    {!fl.isEdit ? (
                      <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }} key={i}>
                        <a style={{ flex: 15 }} href={fl.url}>
                          {fl.fileName}
                        </a>
                        {isAdmin && (
                          <Button
                            style={{ flex: 1 }}
                            onClick={() => {
                              handleDataChange('op', 'file');
                              handleDataChange('deletedata', fl.id);
                              handleDataChange('deletenum', i);
                              handleDataChange('type', 'other');
                              handleDataChange('openDialog', true);
                            }}
                          >
                            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                          </Button>
                        )}
                      </Stack>
                    ) : (
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        key={i}
                      >
                        <TextField
                          style={{ flex: 5 }}
                          value={fl.fileName}
                          onChange={(e) => {
                            const dt = files;
                            dt[i].fileName = e.target.value;
                            setFiles([...dt]);
                          }}
                          placeholder="File Name"
                        />
                        <TextField
                          style={{ flex: 8, marginLeft: '1rem' }}
                          value={fl.url}
                          onChange={(e) => {
                            const dt = files;
                            dt[i].url = e.target.value;
                            setFiles([...dt]);
                          }}
                          placeholder="URL"
                        />
                        <Button
                          style={{ flex: 0.5, marginLeft: '1rem' }}
                          variant="contained"
                          onClick={() => {
                            const file = files[i];
                            if (file.fileName !== '' && file.url !== '') {
                              const dt = new FormData();
                              dt.append('fileName', file.fileName);
                              dt.append('url', file.url);
                              handleFile(files[i], 'add', i);
                            } else {
                              handleDataChange('toastFlag', true);
                              handleDataChange('toastMsg', 'Filename and URL are mandatory');
                              handleDataChange('toastStatus', 'error');
                              clearTimeout(timeout);
                              timeout = setTimeout(() => {
                                handleDataChange('toastFlag', false);
                              }, 1000);
                            }
                          }}
                        >
                          Add
                        </Button>
                      </Stack>
                    )}
                    <hr />
                  </>
                ))
              ) : (
                <Typography>No Files found!!</Typography>
              )}
            </TabPanel>

            {/* Lang and Geo */}
            <TabPanel value={tab} index={1}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Languages:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>English </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.language_En}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('language_En', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>French </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.language_Fr}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('language_Fr', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Geo:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>Canada </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.geo_Canada}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('geo_Canada', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>Provincial </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.geo_Provincial}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('geo_Provincial', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>USA </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.geo_US}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('geo_US', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '7.5rem' }}>Municipal / FSA </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.municipalFSA}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('municipalFSA', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </TabPanel>

            {/* Podcasts */}
            <TabPanel value={tab} index={2}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Podcasts:
              </Typography>
              {data.podcasts.length > 0 ? (
                isAdmin && (
                  <Box>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('isDelete', !data.isDelete);
                      }}
                      checked={data.isDelete}
                    />{' '}
                    Delete
                  </Box>
                )
              ) : (
                <Typography style={{ fontWeight: 'bold' }}>NO PODCAST EXISTS</Typography>
              )}

              {data.isDelete !== true
                ? data.podcasts.map((pd) => (
                    <>
                      <Chip
                        label={pd.title}
                        style={{ marginRight: '1rem', marginBottom: '1rem' }}
                        onClick={() => {
                          const url = window.location.toString();
                          // eslint-disable-next-line no-restricted-globals
                          window.location = url.replace(location.pathname, `/dashboard/podcasts/${pd.tpX_Id}`);
                        }}
                        color="primary"
                        variant="outlined"
                      />
                    </>
                  ))
                : null}
              {data.isDelete
                ? data.podcasts.map((pd) => (
                    <Chip
                      label={pd.title}
                      style={{ marginRight: '1rem', marginBottom: '1rem' }}
                      onClick={() => {
                        const newSet = new Set(podcastDelete);
                        if (newSet.has(pd.tpX_Id)) newSet.delete(pd.tpX_Id);
                        else newSet.add(pd.tpX_Id);
                        setPodcastDelete(newSet);
                      }}
                      color="primary"
                      variant={podcastDelete.has(pd.tpX_Id) ? 'filled' : 'outlined'}
                    />
                  ))
                : null}
              {data.isDelete ? (
                podcastDelete.size !== 0 ? (
                  <Box>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDataChange('openDialog', true)}
                      style={{ width: '160px', marginRight: '1rem' }}
                    >
                      delete
                    </Button>
                  </Box>
                ) : null
              ) : null}
            </TabPanel>
            {/* dialog for delete confirmation */}
            <Dialog
              open={data.openDialog}
              onClose={() => {
                handleDataChange('openDialog', false);
              }}
            >
              <DialogContent>
                {data.type === 'other' ? (
                  <Typography>Are you sure you want to delete this?</Typography>
                ) : podcastDelete.size > 1 ? (
                  <Typography>Are you sure you want to delete these {podcastDelete.size} podcasts?</Typography>
                ) : (
                  <Typography>Are you sure you want to delete this podcast?</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDataChange('openDialog', false)}>Cancel</Button>
                {data.type === 'other' ? (
                  <Button
                    onClick={() => {
                      handleDataChange('openDialog', false);
                      handleDelete();
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleDataChange('openDialog', false);
                      const value = podcastDelete.values();
                      const arr = [];
                      // eslint-disable-next-line no-restricted-syntax
                      for (const id of value) {
                        arr.push(id);
                      }
                      handleBulkDeletePodcast(arr);
                    }}
                  >
                    Delete
                  </Button>
                )}
              </DialogActions>
            </Dialog>

            {/* Third-Party Tracking */}
            <TabPanel value={tab} index={3}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Third-Party Tracking:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>Chartable </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.canTrack_Chartable}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('canTrack_Chartable', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>Podsights </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.canTrack_Podsights}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('canTrack_Podsights', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '6rem' }}>LoopMe </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.canTrack_LoopMe}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('canTrack_LoopMe', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <TextField
                fullWidth
                label="Can Track Note"
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.canTrack_Notes === null ? '' : data.canTrack_Notes}
                onChange={(e) => {
                  handleDataChange('canTrack_Notes', e.target.value);
                }}
                placeholder=""
              />
            </TabPanel>

            {/* Transaction Type */}
            <TabPanel value={tab} index={4}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Transaction Type:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '8rem' }}>Programmatic </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.transactionType_Programmatic}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('transactionType_Programmatic', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '8rem' }}>Direct </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.transactionType_Direct}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('transactionType_Direct', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </TabPanel>

            {/* Ad Read Type, Position and CPM */}
            <TabPanel value={tab} index={5}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Ad Read Type:
              </Typography>
              <Table sx={{ maxWidth: 650, marginBottom: '1rem' }} aria-labelledby="tableTitle" size={'small'}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Client Currency</h3>
                    </TableCell>
                    <TableCell>
                      <ToggleButtonGroup
                        color="primary"
                        value={data.clientCurrency}
                        exclusive
                        aria-label="Platform"
                        onChange={(e, val) => {
                          handleDataChange('clientCurrency', val);
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <ToggleButton value="CAD">CAD</ToggleButton>
                        <ToggleButton value="USD">USD</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell>
                      <h3>Publisher Currency</h3>
                    </TableCell>
                    <TableCell>
                      <ToggleButtonGroup
                        color="primary"
                        value={data.providerCurrency}
                        exclusive
                        aria-label="Platform"
                        onChange={(e, val) => {
                          handleDataChange('providerCurrency', val);
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <ToggleButton value="CAD">CAD</ToggleButton>
                        <ToggleButton value="USD">USD</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table sx={{ width: 1270 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ad Read Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Client CPM</TableCell>
                    <TableCell>Publisher CPM</TableCell>
                    <TableCell>Minimum Buy Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Host Read</h3>
                    </TableCell>
                    <TableCell>
                      <ToggleButtonGroup
                        color="primary"
                        value={data.adType_HostRead}
                        exclusive
                        aria-label="Platform"
                        onChange={(e, val) => {
                          handleDataChange('adType_HostRead', val);
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <ToggleButton value={null}>Not set</ToggleButton>
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_HostRead === null ? '' : data.cpmToClient_HostRead}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_HostRead', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_HostRead === null ? '' : data.cpmToPublisher_HostRead}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_HostRead', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adType_HostRead_MBN === null ? '' : data.adType_HostRead_MBN}
                        onChange={(e) => {
                          handleDataChange('adType_HostRead_MBN', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Client Provided</h3>
                    </TableCell>
                    <TableCell>
                      <ToggleButtonGroup
                        color="primary"
                        value={data.adType_ClientProvided}
                        exclusive
                        aria-label="Platform"
                        onChange={(e, val) => {
                          handleDataChange('adType_ClientProvided', val);
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <ToggleButton value={null}>Not set</ToggleButton>
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_ClientProvided === null ? '' : data.cpmToClient_ClientProvided}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_ClientProvided', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_ClientProvided === null ? '' : data.cpmToPublisher_ClientProvided}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_ClientProvided', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adType_ClientProvided_MBN === null ? '' : data.adType_ClientProvided_MBN}
                        onChange={(e) => {
                          handleDataChange('adType_ClientProvided_MBN', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Network Read</h3>
                    </TableCell>
                    <TableCell>
                      <ToggleButtonGroup
                        color="primary"
                        value={data.adType_NetworkRead}
                        exclusive
                        aria-label="Platform"
                        onChange={(e, val) => {
                          handleDataChange('adType_NetworkRead', val);
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <ToggleButton value={null}>Not set</ToggleButton>
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_NetworkRead === null ? '' : data.cpmToClient_NetworkRead}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_NetworkRead', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_NetworkRead === null ? '' : data.cpmToPublisher_NetworkRead}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_NetworkRead', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adType_NetworkRead_MBN === null ? '' : data.adType_NetworkRead_MBN}
                        onChange={(e) => {
                          handleDataChange('adType_NetworkRead_MBN', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Endorsement</h3>
                    </TableCell>
                    <TableCell>
                      <ToggleButtonGroup
                        color="primary"
                        value={data.adType_Endorsement}
                        exclusive
                        aria-label="Platform"
                        onChange={(e, val) => {
                          handleDataChange('adType_Endorsement', val);
                        }}
                        style={{ marginLeft: '1rem' }}
                      >
                        <ToggleButton value={null}>Not set</ToggleButton>
                        <ToggleButton value={true}>Yes</ToggleButton>
                        <ToggleButton value={false}>No</ToggleButton>
                      </ToggleButtonGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_Endorsement === null ? '' : data.cpmToClient_Endorsement}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_Endorsement', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_Endorsement === null ? '' : data.cpmToPublisher_Endorsement}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_Endorsement', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adType_Endorsement_MBN === null ? '' : data.adType_Endorsement_MBN}
                        onChange={(e) => {
                          handleDataChange('adType_Endorsement_MBN', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography variant="h6" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Ad Position:
              </Typography>
              <Table sx={{ width: 1030 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ad Position</TableCell>
                    <TableCell>Number of Impressions</TableCell>
                    <TableCell>Client CPM</TableCell>
                    <TableCell>Publisher CPM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Pre</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adPosition_Pre === null ? '' : data.adPosition_Pre}
                        onChange={(e) => {
                          handleDataChange('adPosition_Pre', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_PreRoll === null ? '' : data.cpmToClient_PreRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_PreRoll', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_PreRoll === null ? '' : data.cpmToPublisher_PreRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_PreRoll', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Mid</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adPosition_Mid === null ? '' : data.adPosition_Mid}
                        onChange={(e) => {
                          handleDataChange('adPosition_Mid', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_MidRoll === null ? '' : data.cpmToClient_MidRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_MidRoll', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_MidRoll === null ? '' : data.cpmToPublisher_MidRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_MidRoll', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Post</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.adPosition_Post === null ? '' : data.adPosition_Post}
                        onChange={(e) => {
                          handleDataChange('adPosition_Post', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToClient_PostRoll === null ? '' : data.cpmToClient_PostRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_PostRoll', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.clientCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
                        onWheel={(e) => document.activeElement.blur()}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                        value={data.cpmToPublisher_PostRoll === null ? '' : data.cpmToPublisher_PostRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_PostRoll', e.target.value);
                        }}
                        placeholder=""
                        InputProps={{
                          endAdornment:
                            data.providerCurrency === 'CAD' ? (
                              <Typography sx={{ marginLeft: '5px' }}>CAD</Typography>
                            ) : (
                              <Typography sx={{ marginLeft: '5px' }}>USD</Typography>
                            ),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Stack direction="column">
                <TextField
                  label="CPM"
                  type="number"
                  onWheel={(e) => document.activeElement.blur()}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  value={data.cpm}
                  onChange={(e) => {
                    handleDataChange('cpm', e.target.value);
                  }}
                  placeholder=""
                />
                <TextField
                  label="Custom CPM"
                  type="number"
                  onWheel={(e) => document.activeElement.blur()}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  value={data.customCPM}
                  onChange={(e) => {
                    handleDataChange('customCPM', e.target.value);
                  }}
                  placeholder=""
                />
                <TextField
                  fullWidth
                  label="CPM Notes"
                  multiline
                  rows={4}
                  style={{ marginTop: '1rem' }}
                  value={data.cpmNotes}
                  onChange={(e) => {
                    handleDataChange('cpmNotes', e.target.value);
                  }}
                  placeholder=""
                />
              </Stack>
            </TabPanel>

            {/* Monthly downloads & impressions */}
            <TabPanel value={tab} index={6} style={{ marginBottom: '6rem' }}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Monthly Downloads & Impressions
              </Typography>
              <Table sx={{ minWidth: 1050 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Region</TableCell>
                    <TableCell>Downloads</TableCell>
                    <TableCell>Impressions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regions.map((rg, i) => (
                    <TableRow>
                      <TableCell>
                        <h3>{rg.name}</h3>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label=""
                          type="number"
                          onWheel={(e) => document.activeElement.blur()}
                          style={{ marginBottom: '1rem' }}
                          value={data[`monthlyDownloads_${rg.id}`] === null ? '' : data[`monthlyDownloads_${rg.id}`]}
                          onChange={(e) => {
                            handleDataChange(`monthlyDownloads_${rg.id}`, e.target.value);
                          }}
                          placeholder=""
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label=""
                          type="number"
                          onWheel={(e) => document.activeElement.blur()}
                          style={{ marginBottom: '1rem' }}
                          value={
                            data[`monthlyImpressions_${rg.id}`] === null ? '' : data[`monthlyImpressions_${rg.id}`]
                          }
                          onChange={(e) => {
                            handleDataChange(`monthlyImpressions_${rg.id}`, e.target.value);
                          }}
                          placeholder=""
                        />
                        {(data[`monthlyImpressions_${rg.id}`] === null || data[`monthlyImpressions_${rg.id}`] === 0) &&
                          data[`monthlyDownloads_${rg.id}`] > 0 && (
                            <>Expected Impression = {data[`monthlyDownloads_${rg.id}`] * 4}</>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabPanel>

            {/* Ad Position */}
            {/* <TabPanel value={tab} index={7}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Ad Position:
              </Typography>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Ad Position</TableCell>
                    <TableCell>Number of Impressions</TableCell>
                    <TableCell>Client CPM</TableCell>
                    <TableCell>Publisher CPM</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <h3>Pre</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.adPosition_Pre}
                        onChange={(e) => {
                          handleDataChange('adPosition_Pre', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToClient_PreRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_PreRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToPublisher_PreRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_PreRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Mid</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.adPosition_Mid}
                        onChange={(e) => {
                          handleDataChange('adPosition_Mid', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToClient_MidRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_MidRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToPublisher_MidRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_MidRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <h3>Post</h3>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.adPosition_Post}
                        onChange={(e) => {
                          handleDataChange('adPosition_Post', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToClient_PostRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToClient_PostRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label=""
                        type="number"
onWheel={(e) => document.activeElement.blur()}
                          
                        style={{ marginBottom: '1rem' }}
                        value={data.cpmToPublisher_PostRoll}
                        onChange={(e) => {
                          handleDataChange('cpmToPublisher_PostRoll', e.target.value);
                        }}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TabPanel> */}

            {/* Contacts */}
            <TabPanel value={tab} index={7}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Contacts:
              </Typography>
              {data.contacts.length > 0 ? (
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Position</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Description</TableCell>
                      {isAdmin && <TableCell style={{ width: '100px' }}>Action</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.contacts.map((ct, i) => (
                      <TableRow>
                        {ct.isEdit ? (
                          <>
                            <TableCell>
                              <TextField
                                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                                value={ct.name}
                                onChange={(e) => {
                                  data.contacts[i].name = e.target.value;
                                  setData({ ...data });
                                }}
                                placeholder=""
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                                value={ct.position}
                                onChange={(e) => {
                                  data.contacts[i].position = e.target.value;
                                  setData({ ...data });
                                }}
                                placeholder=""
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                                value={ct.email}
                                onChange={(e) => {
                                  data.contacts[i].email = e.target.value;
                                  setData({ ...data });
                                }}
                                placeholder=""
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                onWheel={(e) => document.activeElement.blur()}
                                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                                value={ct.phone}
                                onChange={(e) => {
                                  data.contacts[i].phone = e.target.value;
                                  setData({ ...data });
                                }}
                                placeholder=""
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                                value={ct.discription}
                                onChange={(e) => {
                                  data.contacts[i].discription = e.target.value;
                                  setData({ ...data });
                                }}
                                placeholder=""
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  const dt = data;
                                  if (dt.contacts[i].isNew === true) {
                                    handleContact(dt, 'add', i);
                                  } else {
                                    handleContact(dt, 'save', i);
                                  }
                                }}
                              >
                                Save
                              </Button>
                              <Button
                                style={{ flex: 1 }}
                                onClick={() => {
                                  handleDataChange('op', 'contact');
                                  handleDataChange('deletedata', data.contacts[i].id);
                                  handleDataChange('deletenum', i);
                                  handleDataChange('type', 'other');
                                  handleDataChange('openDialog', true);
                                  // handleContact(data.contacts[i].id, 'delete', i);
                                }}
                              >
                                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                              </Button>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{ct.name}</TableCell>
                            <TableCell>{ct.position}</TableCell>
                            <TableCell>{ct.email}</TableCell>
                            <TableCell>{ct.phone}</TableCell>
                            <TableCell>{ct.discription}</TableCell>
                            <TableCell>
                              {isAdmin && (
                                <Button
                                  onClick={() => {
                                    const dt = data;
                                    dt.contacts[i].isEdit = true;
                                    setData({ ...dt });
                                  }}
                                >
                                  <Iconify icon="eva:edit-fill" width={24} height={24} />
                                </Button>
                              )}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography>No Contacts found!!</Typography>
              )}
              <Stack justifyContent={'center'} alignItems={'center'} style={{ marginTop: '3rem' }}>
                {isAdmin && (
                  <Button
                    variant="contained"
                    style={{ width: '160px' }}
                    onClick={() => {
                      const dt = data;
                      const ct = {
                        name: '',
                        position: '',
                        email: '',
                        phone: '',
                        networkId: id,
                        isEdit: true,
                        isNew: true,
                      };
                      dt.contacts.push(ct);
                      setData({ ...dt });
                    }}
                  >
                    + Add Contact
                  </Button>
                )}
              </Stack>
            </TabPanel>

            {/* CPM */}
            {/* <TabPanel value={tab} index={9}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                CPM:
              </Typography>
              <TextField
                fullWidth
                label="CPM Note"
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.cpmNotes}
                onChange={(e) => {
                  handleDataChange('cpmNotes', e.target.value);
                }}
                placeholder=""
              />
            </TabPanel> */}

            {/* Restrictions */}
            <TabPanel value={tab} index={8}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Restrictions:
              </Typography>
              <TextField
                label="Ad Exclusion Notes"
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.exclusionsNotes === null ? '' : data.exclusionsNotes}
                onChange={(e) => {
                  handleDataChange('exclusionsNotes', e.target.value);
                }}
              />
              <TextField
                label="Ad Frequency Capping Notes"
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: '1rem' }}
                value={data.frequencyCappingNotes === null ? '' : data.frequencyCappingNotes}
                onChange={(e) => {
                  handleDataChange('frequencyCappingNotes', e.target.value);
                }}
              />
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Ad Exclusions:
              </Typography>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Alcohol </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.alcohol}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('alcohol', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Cannabis </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.cannabis}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('cannabis', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Profane/Explicit content </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.explicitContent}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('explicitContent', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Gambling </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.gambling}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('gambling', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Pharma </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.pharma}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('pharma', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} style={{ marginBottom: '1rem' }}>
                <Typography style={{ minWidth: '12rem' }}>Politics </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={data.politics}
                  exclusive
                  aria-label="Platform"
                  onChange={(e, val) => {
                    handleDataChange('politics', val);
                  }}
                  style={{ marginLeft: '1rem' }}
                >
                  <ToggleButton value={null}>Not set</ToggleButton>
                  <ToggleButton value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </TabPanel>

            {/* Demographics */}
            {/* <TabPanel value={tab} index={11}>
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                Demographics:
              </Typography>
              <Stack direction={'column'} alignItems={'left'} style={{ marginBottom: '1rem' }}>
                <Stack direction={'row'} alignItems={'left'} style={{ marginBottom: '1rem' }}>
                  <b style={{ minWidth: '8rem' }}>Age Range</b>
                  <div>
                    <Box sx={{ width: 300 }}>
                      <Slider
                        value={agevalue[0] > -1 ? agevalue : [18, 30]}
                        onChange={(e) => {
                          handleChangeAge(e.target.value);
                        }}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                    <b>
                      Age {data.demoAgeMin} - {data.demoAgeMax}
                    </b>
                  </div>
                </Stack>
                <Stack direction={'row'} alignItems={'left'} style={{ marginBottom: '1rem' }}>
                  <b style={{ minWidth: '8rem' }}>Gender</b>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demoMale', !data.demoMale);
                      }}
                      checked={data.demoMale}
                    />
                    Male{' '}
                  </div>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demofemale', !data.demofemale);
                      }}
                      checked={data.demofemale}
                    />
                    Female{' '}
                  </div>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demoUnknown', !data.demoUnknown);
                      }}
                      checked={data.demoUnknown}
                    />
                    Non-binary{' '}
                  </div>
                </Stack>
                <Stack direction={'row'} alignItems={'left'} style={{ marginBottom: '1rem' }}>
                  <b style={{ minWidth: '8rem' }}>Income Range</b>
                  <div>
                    <Box sx={{ width: 400 }}>
                      <Slider
                        value={incomevalue[0] > -1 ? incomevalue : [0, 10]}
                        onChange={(e) => {
                          handleChangeIncome(e.target.value);
                        }}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                    <b>
                      Income {data.demoIncomeMin}K - {data.demoIncomeMax}K
                    </b>
                  </div>
                </Stack>
                <Stack direction={'row'} alignItems={'left'} style={{ marginBottom: '1rem' }}>
                  <b style={{ minWidth: '8rem' }}>Education</b>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demoEducationHsOrLess', !data.demoEducationHsOrLess);
                      }}
                      checked={data.demoEducationHsOrLess}
                    />
                    HS or less{' '}
                  </div>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demoEducationCollegeOrUni', !data.demoEducationCollegeOrUni);
                      }}
                      checked={data.demoEducationCollegeOrUni}
                    />
                    College/University{' '}
                  </div>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demoEducationGradU', !data.demoEducationGradU);
                      }}
                      checked={data.demoEducationGradU}
                    />
                    Graduate{' '}
                  </div>
                  <div style={{ marginRight: '2rem' }}>
                    <Checkbox
                      onChange={() => {
                        handleDataChange('demoEducationOther', !data.demoEducationOther);
                      }}
                      checked={data.demoEducationOther}
                    />
                    Other
                  </div>
                </Stack>
                {data.demoEducationOther && (
                  <Stack direction={'row'} alignItems={'left'} style={{ marginBottom: '1rem' }}>
                    <TextField
                      style={{ marginBottom: '1rem', marginLeft: '8rem' }}
                      value={data.demoEducationNotes !== '' ? data.demoEducationNotes : ''}
                      onChange={(e) => {
                        handleDataChange('demoEducationNotes', e.target.value);
                      }}
                      placeholder="Specify"
                    />
                  </Stack>
                )}
              </Stack>
            </TabPanel> */}
            {/* toast */}
            <Snackbar
              open={data.toastFlag}
              TransitionComponent={SlideTransition}
              autoHideDuration={2000}
              onClose={() => {
                handleDataChange('toastFlag', false);
              }}
            >
              <Alert severity={data.toastStatus}>{data.toastMsg}</Alert>
            </Snackbar>
          </Card>
        </Container>
      )}
    </Page>
  );
}
