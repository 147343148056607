import * as serviceContext from './serviceContext';

const { REACT_APP_API_ENDPOINT } = process.env;

const options = {};
const headers = new Headers();

headers.append('Access-Control-Allow-Origin', '*');
headers.append('mode', 'cors');
headers.append('Content-Type', 'application/json');

options.headers = headers;

export async function getFilterByUserId(uid) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.get(`${REACT_APP_API_ENDPOINT}/filters/getbyuserid/${uid}`, null, options);
    const res = response.json();
    return res;
  } catch (error) {
    return error;
  }
}

export async function addFilter(data) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.post(
      `${REACT_APP_API_ENDPOINT}/filters/create`,
      JSON.stringify(data),
      options
    );
    const res = response.json();
    return res;
  } catch (error) {
    return error;
  }
}

export async function updateFilter(data) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.post(
      `${REACT_APP_API_ENDPOINT}/filters/update`,
      JSON.stringify(data),
      options
    );
    const res = response.json();
    return res;
  } catch (error) {
    return error;
  }
}

export async function deleteFilter(id) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.del(`${REACT_APP_API_ENDPOINT}/filters?id=${id}`, null, options);
    const res = response.json();
    return res;
  } catch (error) {
    return error;
  }
}
