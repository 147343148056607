import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Snackbar, Alert, Slide } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  //   linkTo: '#',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
export default function AccountPopover() {
  let timeout = 3000;
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});

  const handleDataChange = (fld, val) => {
    data[fld] = val;
    setData({ ...data });
  };

  useEffect(() => {
    if (localStorage.getItem('userData') === null || localStorage.getItem('userData') === 'undefined') {
      handleDataChange('toastFlag', true);
      handleDataChange('toastMsg', 'Check your Internet Connection!! Try Again');
      handleDataChange('toastStatus', 'error');
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        handleDataChange('toastFlag', false);
        navigate(`/login`, { replace: true });
      }, 2000);
    } else {
      setUserData(JSON.parse(localStorage.getItem('userData')));
    }
  }, []);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={(e) => {
          handleDataChange('popover', e.currentTarget);
        }}
        sx={{
          p: 0,
          ...(data.popover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(data.popover)}
        anchorEl={data.popover}
        onClose={() => {
          handleDataChange('popover', null);
        }}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData.firstName} {userData.lastName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={() => {
                handleDataChange('popover', null);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            navigate(`/login`, { replace: true });
          }}
          sx={{ m: 1 }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
      <Snackbar open={data.toastFlag} TransitionComponent={SlideTransition}>
        <Alert severity={data.toastStatus}>{data.toastMsg}</Alert>
      </Snackbar>
    </>
  );
}
