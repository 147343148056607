/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

export default function ShortingComp(props) {
  return (
    <span className="d-contant" style={{ float: "right" }}>
      <span onClick={() => { props.shortNetwork("asc") }} style={{ fontSize: "11px", padding: "0px 0px 0px 3px", cursor: "pointer", fontWeight: "1000" }}>↑</span>
      <span onClick={() => { props.shortNetwork("dasc") }} style={{ fontSize: "11px", padding: "0px 0px 0px 3px", cursor: "pointer", fontWeight: "1000" }}>↓</span>
    </span>
  );
}
