/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */

// @mui
import React, { useEffect, useState, useRef } from 'react';
import * as xlsx from 'xlsx/xlsx.mjs';
import { useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import {
  Checkbox,
  Container,
  Stack,
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  Divider,
  styled,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ShortingComp from '../components/ShortingComp';
import { getAllNetworks } from '../services/networkServices';
// components
// import { networks } from '../_mock/networks';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// components
import { ProductFilterSidebar, AddNetworkDialog } from '../sections/@dashboard/products';

export default function NetworksList() {
  const tableRef = useRef(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const allFields = [
    'name',
    'lang',
    'geo',
    'networkNotes',
    'track',
    'tran',
    'adtype',
    'cpm',
    'customCPM',
    'cpmNotes',
    'downloads',
    'positions',
    'impressions',
    'monthlyDownloads_CA',
    'monthlyDownloads_US',
    'monthlyDownloads_BC',
    'monthlyDownloads_AB',
    'monthlyDownloads_SK',
    'monthlyDownloads_MA',
    'monthlyDownloads_ON',
    'monthlyDownloads_QB',
    'monthlyDownloads_AT',
    'adPosition_Pre',
    'adPosition_Mid',
    'adPosition_Post',
    'monthlyImpressions_CA',
    'monthlyImpressions_US',
    'monthlyImpressions_BC',
    'monthlyImpressions_AB',
    'monthlyImpressions_SK',
    'monthlyImpressions_MA',
    'monthlyImpressions_ON',
    'monthlyImpressions_QB',
    'monthlyImpressions_AT',
    'cpmToClient_HostRead',
    'cpmToPublisher_HostRead',
    'cpmToClient_ClientProvided',
    'cpmToPublisher_ClientProvided',
    'cpmToClient_NetworkRead',
    'cpmToPublisher_NetworkRead',
    'cpmToClient_Endorsement',
    'cpmToPublisher_Endorsement',
    'cpmToClient_PreRoll',
    'cpmToPublisher_PreRoll',
    'cpmToClient_MidRoll',
    'cpmToPublisher_MidRoll',
    'cpmToClient_PostRoll',
    'cpmToPublisher_PostRoll',
    'contacts',
    'exclusionsNotes',
    'frequencyCappingNotes',
    'adExclusions',
    //   demographics //
    // 'age',
    // 'gender',
    // 'income',
    // 'education',
  ];
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const [allNetworkList, setAllNetworkList] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [filds, setFields] = useState(allFields);
  const [nameSearch, setNameSearch] = useState('');
  const [open, setOpen] = useState(false);

  const tableFilds = [
    { lable: 'Language ', value: 'lang' },
    { lable: 'Geo', value: 'geo' },
    { lable: 'Network Notes', value: 'networkNotes' },
    { lable: 'Can Track', value: 'track' },
    { lable: 'Transaction Type', value: 'tran' },
    { lable: 'Ad Read Type', value: 'adtype' },
    {
      lable: 'Ad Read Type CPM',
      value: 'adTypeCPM',
      sub: [
        { lable: 'Host Read Client CPM', value: 'cpmToClient_HostRead' },
        { lable: 'Host Read Publisher CPM', value: 'cpmToPublisher_HostRead' },
        { lable: 'Client Provided Client CPM', value: 'cpmToClient_ClientProvided' },
        { lable: 'Client Provided Publisher CPM', value: 'cpmToPublisher_ClientProvided' },
        { lable: 'Network Read Client CPM', value: 'cpmToClient_NetworkRead' },
        { lable: 'Network Read Publisher CPM', value: 'cpmToPublisher_NetworkRead' },
        { lable: 'Endorsement Client CPM', value: 'cpmToClient_Endorsement' },
        { lable: 'Endorsement Publisher CPM', value: 'cpmToPublisher_Endorsement' },
      ],
    },
    {
      lable: 'Ad Position CPM',
      value: 'adPositionCPM',
      sub: [
        { lable: 'Pre Roll Client CPM', value: 'cpmToClient_PreRoll' },
        { lable: 'Pre Roll Publisher CPM', value: 'cpmToPublisher_PreRoll' },
        { lable: 'Mid Roll Client CPM', value: 'cpmToClient_MidRoll' },
        { lable: 'Mid Roll Publisher CPM', value: 'cpmToPublisher_MidRoll' },
        { lable: 'Post Roll Client CPM', value: 'cpmToClient_PostRoll' },
        { lable: 'Post Roll Publisher CPM', value: 'cpmToPublisher_PostRoll' },
      ],
    },
    { lable: 'CPM', value: 'cpm' },
    { lable: 'Custom CPM', value: 'customCPM' },
    { lable: 'CPM Notes', value: 'cpmNotes' },
    {
      lable: 'Downloads',
      value: 'downloads',
      sub: [
        { lable: 'CA_DL', value: 'monthlyDownloads_CA' },
        { lable: 'US_DL', value: 'monthlyDownloads_US' },
        { lable: 'BC_DL', value: 'monthlyDownloads_BC' },
        { lable: 'AB_DL', value: 'monthlyDownloads_AB' },
        { lable: 'SK_DL', value: 'monthlyDownloads_SK' },
        { lable: 'MA_DL', value: 'monthlyDownloads_MA' },
        { lable: 'ON_DL', value: 'monthlyDownloads_ON' },
        { lable: 'QB_DL', value: 'monthlyDownloads_QB' },
        { lable: 'AT_DL', value: 'monthlyDownloads_AT' },
      ],
    },
    {
      lable: 'Ad Positions',
      value: 'positions',
      sub: [
        { lable: 'Pre', value: 'adPosition_Pre' },
        { lable: 'Mid', value: 'adPosition_Mid' },
        { lable: 'Post', value: 'adPosition_Post' },
      ],
    },
    {
      lable: 'Impressions',
      value: 'impressions',
      sub: [
        { lable: 'CA_IMP', value: 'monthlyImpressions_CA' },
        { lable: 'US_IMP', value: 'monthlyImpressions_US' },
        { lable: 'BC_IMP', value: 'monthlyImpressions_BC' },
        { lable: 'AB_IMP', value: 'monthlyImpressions_AB' },
        { lable: 'SK_IMP', value: 'monthlyImpressions_SK' },
        { lable: 'MA_IMP', value: 'monthlyImpressions_MA' },
        { lable: 'ON_IMP', value: 'monthlyImpressions_ON' },
        { lable: 'QB_IMP', value: 'monthlyImpressions_QB' },
        { lable: 'AT_IMP', value: 'monthlyImpressions_AT' },
      ],
    },
    { lable: 'Contacts', value: 'contacts' },
    { lable: 'Ad Exclusion Notes', value: 'exclusionsNotes' },
    { lable: 'Ad Frequency Capping Notes', value: 'frequencyCappingNotes' },
    { lable: 'Ad Exclusions', value: 'adExclusions' },
    { lable: 'Client Currency', value: 'clientCurrency' },
    { lable: 'Publisher Currency', value: 'providerCurrency' },
    // {
    //   lable: 'Demographics',
    //   value: 'demographics',
    //   sub: [
    //     { lable: 'Age Group', value: 'age' },
    //     { lable: 'Gender', value: 'gender' },
    //     { lable: 'Income', value: 'income' },
    //     { lable: 'Education', value: 'education' },
    //   ],
    // },
  ];
  useEffect(() => {
    if (localStorage.getItem('userData') !== null) {
      setIsAdmin(JSON.parse(localStorage.getItem('userData')).isAdmin);
    }
  }, []);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const TableCellDesign = styled(TableCell)`
    font-size: 0.75rem;
    border: 1px solid rgba(241, 243, 244, 1);
    .tooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `;

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem('userData') !== null) {
      getAllNetworks().then((res) => {
        if (res.success) {
          const data = res.data.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
          );
          setAllNetworkList([...data]);
          setNetworkList([...data]);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (filters.length === 0 && nameSearch === '') {
      setNetworkList([...allNetworkList]);
    } else {
      let filteredVal = allNetworkList;
      if (nameSearch !== '') {
        filteredVal = filteredVal.filter((nt, i) => nt.name.toLowerCase().includes(nameSearch.toLowerCase()));
      }
      filters.forEach((elem, i) => {
        if (elem.type === 'simple') {
          filteredVal = filteredVal.filter((net) => {
            return net[elem.flt] === true;
          });
        } else if (elem.type === 'range' && elem.rangeType === 'min') {
          filteredVal = filteredVal.filter((net) => {
            return net[elem.flt] >= elem.val;
          });
        } else if (elem.type === 'range' && elem.rangeType === 'max') {
          filteredVal = filteredVal.filter((net) => {
            return net[elem.flt] <= elem.val;
          });
          filteredVal = filteredVal.filter((net) => {
            return net[elem.flt] >= 1;
          });
        }
      });
      setNetworkList([...filteredVal]);
    }
  }, [filters, nameSearch]);

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const shortSelectedNetworks = (fldItem, type) => {
    const ntl = networkList;
    if (fldItem === 'name') {
      ntl.sort((a, b) =>
        a[fldItem].toLowerCase() > b[fldItem].toLowerCase()
          ? 1
          : b[fldItem].toLowerCase() > a[fldItem].toLowerCase()
            ? -1
            : 0
      );
    } else {
      ntl.sort((a, b) => (a[fldItem] > b[fldItem] ? 1 : b[fldItem] > a[fldItem] ? -1 : 0));
    }
    if (type === 'dasc') {
      ntl.reverse();
    }
    setNetworkList([...ntl]);
  };

  const updateFildView = (fld, check) => {
    let updatedList = [...filds];
    if (check) {
      filds.push(fld);
      updatedList = [...filds];
    } else {
      updatedList.splice(filds.indexOf(fld), 1);
    }
    setFields(updatedList);
  };

  // eslint-disable-next-line no-return-assign
  const selectedatcpm = tableFilds[6].sub.map((fld) => fld.value).filter((e) => filds.indexOf(e) > -1).length;
  const selectedatcpmFlds = tableFilds[6].sub.filter((e) => filds.indexOf(e.value) > -1);
  const selectedapcpm = tableFilds[7].sub.map((fld) => fld.value).filter((e) => filds.indexOf(e) > -1).length;
  const selectedapcpmFlds = tableFilds[7].sub.filter((e) => filds.indexOf(e.value) > -1);
  const selecteddownloads = tableFilds[11].sub.map((fld) => fld.value).filter((e) => filds.indexOf(e) > -1).length;
  const selecteddownloadsFlds = tableFilds[11].sub.filter((e) => filds.indexOf(e.value) > -1);
  const selectedadpos = tableFilds[12].sub.map((fld) => fld.value).filter((e) => filds.indexOf(e) > -1).length;
  const selectedadposFlds = tableFilds[12].sub.filter((e) => filds.indexOf(e.value) > -1);
  const selectedimp = tableFilds[13].sub.map((fld) => fld.value).filter((e) => filds.indexOf(e) > -1).length;
  const selectedimpFlds = tableFilds[13].sub.filter((e) => filds.indexOf(e.value) > -1);
  //   demographics
  //   const selecteddemo = tableFilds[12].sub.map((fld) => fld.value).filter((e) => filds.indexOf(e) > -1).length;
  return (
    <Page title="Dashboard">
      <Container maxWidth="xxl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Networks
          </Typography>
        </Stack>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-start" sx={{ mb: 1 }}>
          <Stack
            direction="row"
            spacing={1}
            flexShrink={0}
            sx={{ my: 1 }}
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <div>
              {/* dialog for add network */}
              <AddNetworkDialog popUp={handleCloseFilter} isOpen={open} isPodcast={false} />
              <ProductFilterSidebar
                isPodcast={false}
                isOpenFilter={openFilter}
                filters={(filters) => {
                  setFilters(filters);
                }}
                onOpenFilter={handleOpenFilter}
                onCloseFilter={handleCloseFilter}
                clearAll={() => {
                  setFilters([...[]]);
                }}
                nameSearch={(nameSearch) => {
                  setNameSearch(nameSearch);
                }}
              />
              <>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button
                        disableRipple
                        color="inherit"
                        endIcon={<Iconify icon="ic:round-filter-list" />}
                        {...bindTrigger(popupState)}
                      >
                        Display Options
                      </Button>
                      <Menu
                        {...bindMenu(popupState)}
                        style={{ marginBottom: '50px', minWidth: '1000px', height: '550px' }}
                      >
                        <MenuItem>
                          <Checkbox
                            onChange={() => {
                              if (filds.length === allFields.length) {
                                setFields(['name']);
                              } else {
                                setFields(allFields);
                              }
                            }}
                            checked={filds.length === allFields.length}
                          />
                          All Fields
                        </MenuItem>
                        <Divider style={{ width: '100%' }} />
                        <div style={{ overflowX: 'auto' }}>
                          {tableFilds.map((fld, i) => [
                            <MenuItem key={i}>
                              {!fld.sub && (
                                <Checkbox
                                  onChange={() => {
                                    updateFildView(fld.value, !filds.includes(fld.value));
                                  }}
                                  checked={filds.includes(fld.value)}
                                />
                              )}
                              {fld.lable}{' '}
                              {!!fld.sub &&
                                fld.sub.map((fldSub, i) => (
                                  <div key={i}>
                                    <MenuItem style={{ marginLeft: '1rem' }}>
                                      <Checkbox
                                        onChange={() => {
                                          updateFildView(fldSub.value, !filds.includes(fldSub.value));
                                        }}
                                        checked={filds.includes(fldSub.value)}
                                      />
                                      {fldSub.lable}
                                    </MenuItem>
                                  </div>
                                ))}
                            </MenuItem>,
                          ])}
                        </div>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </>
            </div>
            {isAdmin ? (
              <Button
                variant="soft"
                onClick={() => {
                  setOpen(true);
                }}
                style={{ display: 'flex', marginLeft: 'auto' }}
              >
                Add Network
              </Button>
            ) : null}

            <DownloadTableExcel filename="Networks" sheet="networks" currentTableRef={tableRef.current}>
              <Button disableRipple color="inherit" endIcon={<Iconify icon="material-symbols:upload-file-outline" />}>
                Export Data
              </Button>
            </DownloadTableExcel>
          </Stack>
        </Stack>
        <Card spacing={3} style={{ padding: '2rem' }}>
          <TableContainer>
            <div style={{ marginBottom: 20, fontWeight: 600, position: 'sticky', left: '0' }}>
              Showing {networkList.length} networks out of {allNetworkList.length} total networks
            </div>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCellDesign rowSpan={2}>
                    Name{' '}
                    <ShortingComp
                      shortNetwork={(type) => {
                        shortSelectedNetworks('name', type);
                      }}
                    />
                  </TableCellDesign>
                  {(filds.length === 0 || filds.includes('lang')) && (
                    <TableCellDesign rowSpan={2}>Language </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('geo')) && <TableCellDesign rowSpan={2}>Geo </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('networkNotes')) && (
                    <TableCellDesign rowSpan={2}>Network Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('track')) && (
                    <TableCellDesign rowSpan={2}>Can Track </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('tran')) && (
                    <TableCellDesign rowSpan={2}>Transaction Type </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('adtype')) && (
                    <TableCellDesign rowSpan={2}>Ad Read Type </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('clientCurrency')) && (
                    <TableCellDesign rowSpan={2}>Client Currency </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('providerCurrency')) && (
                    <TableCellDesign rowSpan={2}>Publisher Currency </TableCellDesign>
                  )}
                  {(filds.length === 0 || selectedatcpm > 0) && (
                    <TableCellDesign colSpan={selectedatcpm} style={{ textAlign: 'center' }}>
                      Ad Read Type CPM{' '}
                    </TableCellDesign>
                  )}
                  {(filds.length === 0 || selectedapcpm > 0) && (
                    <TableCellDesign colSpan={selectedapcpm} style={{ textAlign: 'center' }}>
                      Ad Position CPM{' '}
                    </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('cpm')) && <TableCellDesign rowSpan={2}>CPM </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('customCPM')) && (
                    <TableCellDesign rowSpan={2}>Custom CPM </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('cpmNotes')) && (
                    <TableCellDesign rowSpan={2}>CPM Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || selecteddownloads > 0) && (
                    <TableCellDesign colSpan={selecteddownloads} style={{ textAlign: 'center' }}>
                      Downloads{' '}
                    </TableCellDesign>
                  )}
                  {(filds.length === 0 || selectedadpos > 0) && (
                    <TableCellDesign colSpan={selectedadpos} style={{ textAlign: 'center' }}>
                      Ad Positions{' '}
                    </TableCellDesign>
                  )}
                  {(filds.length === 0 || selectedimp > 0) && (
                    <TableCellDesign colSpan={selectedimp} style={{ textAlign: 'center' }}>
                      Impressions{' '}
                    </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('contacts')) && (
                    <TableCellDesign rowSpan={2}>Contacts </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('exclusionsNotes')) && (
                    <TableCellDesign rowSpan={2}>Exclusion Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('frequencyCappingNotes')) && (
                    <TableCellDesign rowSpan={2}>Frequency Capping Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('adExclusions')) && (
                    <TableCellDesign rowSpan={2}>Ad Exclusions </TableCellDesign>
                  )}
                  {/* demographics */}
                  {/* {(filds.length === 0 || selecteddemo > 0) && (
                    <TableCellDesign colSpan={selecteddemo} style={{ textAlign: 'center' }}>
                      Demographics{' '}
                    </TableCellDesign>
                  )} */}
                </TableRow>
                <TableRow>
                  {selectedatcpmFlds.map((atcpm) => {
                    return (
                      <TableCellDesign key={atcpm.lable}>
                        {atcpm.lable}{' '}
                        <ShortingComp
                          shortNetwork={(type) => {
                            shortSelectedNetworks(atcpm.value, type);
                          }}
                        />
                      </TableCellDesign>
                    );
                  })}
                  {selectedapcpmFlds.map((apcpm) => {
                    return (
                      <TableCellDesign key={apcpm.lable}>
                        {apcpm.lable}{' '}
                        <ShortingComp
                          shortNetwork={(type) => {
                            shortSelectedNetworks(apcpm.value, type);
                          }}
                        />
                      </TableCellDesign>
                    );
                  })}
                  {selecteddownloadsFlds.map((downloads) => {
                    return (
                      <TableCellDesign key={downloads.lable}>
                        {downloads.lable}{' '}
                        <ShortingComp
                          shortNetwork={(type) => {
                            shortSelectedNetworks(downloads.value, type);
                          }}
                        />
                      </TableCellDesign>
                    );
                  })}
                  {selectedadposFlds.map((adpos) => {
                    return (
                      <TableCellDesign key={adpos.lable}>
                        {adpos.lable}{' '}
                        <ShortingComp
                          shortNetwork={(type) => {
                            shortSelectedNetworks(adpos.value, type);
                          }}
                        />
                      </TableCellDesign>
                    );
                  })}
                  {selectedimpFlds.map((imp) => {
                    return (
                      <TableCellDesign key={imp.lable}>
                        {imp.lable}{' '}
                        <ShortingComp
                          shortNetwork={(type) => {
                            shortSelectedNetworks(imp.value, type);
                          }}
                        />
                      </TableCellDesign>
                    );
                  })}
                  {/* demographics */}
                  {/* {(filds.length === 0 || filds.includes('age')) && <TableCellDesign>Age</TableCellDesign>}
                  {(filds.length === 0 || filds.includes('gender')) && <TableCellDesign>Gender </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('income')) && <TableCellDesign>Income </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('education')) && <TableCellDesign>Education</TableCellDesign>} */}
                  {/* <TableCellDesign>Actions </TableCellDesign> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {networkList.map((net) => (
                  <TableRow key={net.id}>
                    <TableCellDesign>
                      <Typography
                        variant="h6"
                        gutterBottom
                        style={{ color: 'blue' }}
                        onClick={() => {
                          const url = window.location.toString();
                          // eslint-disable-next-line no-restricted-globals
                          window.location = url.concat(`/${net.id}`);
                        }}
                      >
                        {net.name}
                      </Typography>
                    </TableCellDesign>
                    {(filds.length === 0 || filds.includes('lang')) && (
                      <TableCellDesign>
                        {net.language_En && (
                          <div>
                            • English <br />
                          </div>
                        )}
                        {net.language_Fr && <div>• French</div>}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('geo')) && (
                      <TableCellDesign>
                        {net.geo_Canada && (
                          <div>
                            • Canada <br />
                          </div>
                        )}
                        {net.geo_Provincial && (
                          <div>
                            • Provincial <br />
                          </div>
                        )}
                        {net.municipalFSA && (
                          <div>
                            • Municipal / FSA <br />
                          </div>
                        )}
                        {net.geo_US && (
                          <div>
                            • United States <br />
                          </div>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('networkNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>
                        <div className="tooltip" title={net.networkNotes}>
                          {net.networkNotes}
                        </div>
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('track')) && (
                      <TableCellDesign>
                        {net.canTrack_Chartable && (
                          <div>
                            • Chartable <br />
                          </div>
                        )}
                        {net.canTrack_Podsights && (
                          <div>
                            • Podsights <br />
                          </div>
                        )}
                        {net.canTrack_LoopMe && (
                          <div>
                            • LoopMe <br />
                          </div>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('tran')) && (
                      <TableCellDesign>
                        {' '}
                        {net.transactionType_Programmatic && (
                          <div>
                            • Programmatic <br />
                          </div>
                        )}
                        {net.transactionType_Direct && (
                          <div>
                            • Direct <br />
                          </div>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('adtype')) && (
                      <TableCellDesign>
                        {net.adType_HostRead && (
                          <div>
                            • Host Read <br />
                          </div>
                        )}
                        {net.adType_ClientProvided && (
                          <div>
                            • Client Provided <br />
                          </div>
                        )}
                        {net.adType_NetworkRead && (
                          <div>
                            • Network Read <br />
                          </div>
                        )}
                        {net.adType_Endorsement && (
                          <div>
                            • Endorsement <br />
                          </div>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('clientCurrency')) && (
                      <TableCellDesign>{net.clientCurrency}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('providerCurrency')) && (
                      <TableCellDesign>{net.providerCurrency}</TableCellDesign>
                    )}
                    {selectedatcpmFlds.map((atcpm) => {
                      return <TableCellDesign key={atcpm.lable}>{net[atcpm.value]} </TableCellDesign>;
                    })}
                    {selectedapcpmFlds.map((apcpm) => {
                      return <TableCellDesign key={apcpm.lable}>{net[apcpm.value]} </TableCellDesign>;
                    })}
                    {(filds.length === 0 || filds.includes('cpm')) && <TableCellDesign>{net.cpm}</TableCellDesign>}
                    {(filds.length === 0 || filds.includes('customCPM')) && (
                      <TableCellDesign>{net.customCPM}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('cpmNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>
                        <div className="tooltip" title={net.cpmNotes}>
                          {net.cpmNotes}
                        </div>
                      </TableCellDesign>
                    )}
                    {selecteddownloadsFlds.map((downloads) => {
                      return <TableCellDesign key={downloads.lable}>{net[downloads.value]} </TableCellDesign>;
                    })}
                    {selectedadposFlds.map((adpos) => {
                      return <TableCellDesign key={adpos.lable}>{net[adpos.value]} </TableCellDesign>;
                    })}
                    {selectedimpFlds.map((imp) => {
                      return <TableCellDesign key={imp.lable}>{net[imp.value]} </TableCellDesign>;
                    })}
                    {/* contacts */}
                    {(filds.length === 0 || filds.includes('contacts')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>
                        {net.contacts.map((con) => (
                          <div key={con.email} className="tooltip" title={con.email}>
                            • {con.name} {con.email} <br />
                          </div>
                        ))}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('exclusionsNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>
                        <div className="tooltip" title={net.exclusionsNotes}>
                          {net.exclusionsNotes}
                        </div>
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('frequencyCappingNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>
                        <div className="tooltip" title={net.frequencyCappingNotes}>
                          {net.frequencyCappingNotes}
                        </div>
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('adExclusions')) && (
                      <TableCellDesign>
                        {net.alcohol && <div>• Alcohol</div>}
                        {net.cannabis && <div>• Cannabis</div>}
                        {net.explicitContent && <div>• Profane/Explicit Content</div>}
                        {net.gambling && <div>• Gambling</div>}
                        {net.pharma && <div>• Pharma</div>}
                        {net.politics && <div>• Politics</div>}
                      </TableCellDesign>
                    )}
                    {/* demographics */}
                    {/* {(filds.length === 0 || filds.includes('age')) && (
                      <TableCellDesign>
                        {net.demoAgeMin && net.demoAgeMax && (
                          <>
                            {net.demoAgeMin}-{net.demoAgeMax}
                          </>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('gender')) && (
                      <TableCellDesign>
                        {net.demoMale && (
                          <div>
                            • Male <br />
                          </div>
                        )}
                        {net.demoFemale && (
                          <div>
                            • Female <br />
                          </div>
                        )}
                        {net.demoUnknown && (
                          <div>
                            • Unknown <br />
                          </div>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('income')) && (
                      <TableCellDesign>
                        {net.demoIncomeMin && net.demoIncomeMax && (
                          <>
                            {net.demoIncomeMin}K-{net.demoIncomeMax}K
                          </>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('education')) && (
                      <TableCellDesign>
                        {net.demoEducationHsOrLess && (
                          <div>
                            • HS or less <br />
                          </div>
                        )}
                        {net.demoEducationCollegeOrUni && (
                          <div>
                            • College/University <br />
                          </div>
                        )}
                        {net.demoEducationGradU && (
                          <div>
                            • Graduate <br />
                          </div>
                        )}
                        {net.demoEducationOther && (
                          <div>
                            • Other <br />
                            {!!net.demoEducationNotes && <>Note: {net.demoEducationNotes}</>}
                          </div>
                        )}
                      </TableCellDesign>
                    )} */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div style={{ marginTop: 20, marginBottom: 20, fontWeight: 600, position: 'sticky', left: '0' }}>
              Showing {networkList.length} networks out of {allNetworkList.length} total networks
            </div>
          </TableContainer>

          <TableContainer style={{ display: 'none' }}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" ref={tableRef} size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCellDesign>Name </TableCellDesign>
                  {(filds.length === 0 || filds.includes('lang')) && (
                    <>
                      <TableCellDesign>Language English </TableCellDesign>
                      <TableCellDesign>Language French </TableCellDesign>
                    </>
                  )}
                  {(filds.length === 0 || filds.includes('networkNotes')) && (
                    <TableCellDesign>Network Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('geo')) && (
                    <>
                      <TableCellDesign>Geo Canada </TableCellDesign>
                      <TableCellDesign>Geo Provincial </TableCellDesign>
                      <TableCellDesign>Geo Municipal/FSA </TableCellDesign>
                      <TableCellDesign>Geo United States </TableCellDesign>
                    </>
                  )}
                  {(filds.length === 0 || filds.includes('track')) && (
                    <>
                      <TableCellDesign>Can Track Chartable </TableCellDesign>
                      <TableCellDesign>Can Track Podsights </TableCellDesign>
                      <TableCellDesign>Can Track LoopMe </TableCellDesign>
                    </>
                  )}
                  {(filds.length === 0 || filds.includes('tran')) && (
                    <>
                      <TableCellDesign>Transaction Type Programmatic </TableCellDesign>
                      <TableCellDesign>Transaction Type Direct </TableCellDesign>
                    </>
                  )}
                  {(filds.length === 0 || filds.includes('adtype')) && (
                    <>
                      <TableCellDesign>Ad Read Type Host Read</TableCellDesign>
                      <TableCellDesign>Ad Read Type Client Provided</TableCellDesign>
                      <TableCellDesign>Ad Read Type Network Read</TableCellDesign>
                      <TableCellDesign>Ad Read Type Endorsement</TableCellDesign>
                    </>
                  )}
                  {(filds.length === 0 || filds.includes('clientCurrency')) && (
                    <TableCellDesign>Client Currency </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('providerCurrency')) && (
                    <TableCellDesign>Publisher Currency </TableCellDesign>
                  )}
                  {selectedatcpmFlds.map((atcpm) => {
                    return <TableCellDesign key={atcpm.lable}>{atcpm.lable} </TableCellDesign>;
                  })}
                  {selectedapcpmFlds.map((apcpm) => {
                    return <TableCellDesign key={apcpm.lable}>{apcpm.lable} </TableCellDesign>;
                  })}
                  {(filds.length === 0 || filds.includes('cpm')) && <TableCellDesign>CPM </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('customCPM')) && (
                    <TableCellDesign>Custom CPM </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('cpmNotes')) && <TableCellDesign>CPM Notes </TableCellDesign>}

                  {selecteddownloadsFlds.map((downloads) => {
                    return <TableCellDesign key={downloads.lable}>{downloads.lable} </TableCellDesign>;
                  })}
                  {selectedadposFlds.map((adpos) => {
                    return <TableCellDesign key={adpos.lable}>{adpos.lable} </TableCellDesign>;
                  })}
                  {selectedimpFlds.map((imp) => {
                    return <TableCellDesign key={imp.lable}>{imp.lable} </TableCellDesign>;
                  })}
                  {(filds.length === 0 || filds.includes('contacts')) && (
                    <>
                      <TableCellDesign>Name </TableCellDesign>
                      <TableCellDesign>Email </TableCellDesign>
                    </>
                  )}
                  {(filds.length === 0 || filds.includes('exclusionsNotes')) && (
                    <TableCellDesign>Exclusion Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('frequencyCappingNotes')) && (
                    <TableCellDesign>Frequency Capping Notes </TableCellDesign>
                  )}
                  {(filds.length === 0 || filds.includes('adExclusions')) && (
                    <>
                      <TableCellDesign>Alcohol</TableCellDesign>
                      <TableCellDesign>Cannabis</TableCellDesign>
                      <TableCellDesign>Profane/Explicit Content</TableCellDesign>
                      <TableCellDesign>Gambling</TableCellDesign>
                      <TableCellDesign>Pharma</TableCellDesign>
                      <TableCellDesign>Politics</TableCellDesign>
                    </>
                  )}
                  {/* Demographics */}
                  {/* {(filds.length === 0 || filds.includes('age')) && <TableCellDesign>Age </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('gender')) && <TableCellDesign>Gender </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('income')) && <TableCellDesign>Income </TableCellDesign>}
                  {(filds.length === 0 || filds.includes('education')) && <TableCellDesign>Education </TableCellDesign>} */}
                  {/* <TableCellDesign>Actions </TableCellDesign> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {networkList.map((net) => (
                  <TableRow key={net.id}>
                    <TableCellDesign>
                      <Typography>
                        {net.name}
                        {/* <br />
                          <button>Edit</button> <button>Podcasts</button>{' '} */}
                      </Typography>
                    </TableCellDesign>
                    {(filds.length === 0 || filds.includes('lang')) && (
                      <>
                        <TableCellDesign>
                          {net.language_En !== null ? (net.language_En === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.language_Fr !== null ? (net.language_Fr === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                      </>
                    )}
                    {(filds.length === 0 || filds.includes('networkNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>{net.networkNotes}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('geo')) && (
                      <>
                        <TableCellDesign>
                          {net.geo_Canada !== null ? (net.geo_Canada === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.geo_Provincial !== null ? (net.geo_Provincial === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.municipalFSA !== null ? (net.municipalFSA === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.geo_US !== null ? (net.geo_US === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                      </>
                    )}
                    {(filds.length === 0 || filds.includes('track')) && (
                      <>
                        <TableCellDesign>
                          {net.canTrack_Chartable !== null
                            ? net.canTrack_Chartable === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.canTrack_Podsights !== null
                            ? net.canTrack_Podsights === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.canTrack_LoopMe !== null ? (net.canTrack_LoopMe === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                      </>
                    )}
                    {(filds.length === 0 || filds.includes('tran')) && (
                      <>
                        <TableCellDesign>
                          {net.transactionType_Programmatic !== null
                            ? net.transactionType_Programmatic === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.transactionType_Direct !== null
                            ? net.transactionType_Direct === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                      </>
                    )}
                    {(filds.length === 0 || filds.includes('adtype')) && (
                      <>
                        <TableCellDesign>
                          {net.adType_HostRead !== null ? (net.adType_HostRead === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.adType_ClientProvided !== null
                            ? net.adType_ClientProvided === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.adType_NetworkRead !== null
                            ? net.adType_NetworkRead === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.adType_Endorsement !== null
                            ? net.adType_Endorsement === true
                              ? 'Yes'
                              : 'No'
                            : 'Not Set'}{' '}
                        </TableCellDesign>
                      </>
                    )}
                    {(filds.length === 0 || filds.includes('clientCurrency')) && (
                      <TableCellDesign>{net.clientCurrency}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('providerCurrency')) && (
                      <TableCellDesign>{net.providerCurrency}</TableCellDesign>
                    )}
                    {selectedatcpmFlds.map((atcpm) => {
                      return <TableCellDesign key={atcpm.lable}>{net[atcpm.value]} </TableCellDesign>;
                    })}
                    {selectedapcpmFlds.map((apcpm) => {
                      return <TableCellDesign key={apcpm.lable}>{net[apcpm.value]} </TableCellDesign>;
                    })}
                    {(filds.length === 0 || filds.includes('cpm')) && <TableCellDesign>{net.cpm}</TableCellDesign>}
                    {(filds.length === 0 || filds.includes('customCPM')) && (
                      <TableCellDesign>{net.customCPM}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('cpmNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>{net.cpmNotes}</TableCellDesign>
                    )}
                    {selecteddownloadsFlds.map((downloads) => {
                      return <TableCellDesign key={downloads.lable}>{net[downloads.value]} </TableCellDesign>;
                    })}
                    {selectedadposFlds.map((adpos) => {
                      return <TableCellDesign key={adpos.lable}>{net[adpos.value]} </TableCellDesign>;
                    })}
                    {selectedimpFlds.map((imp) => {
                      return <TableCellDesign key={imp.lable}>{net[imp.value]} </TableCellDesign>;
                    })} 
                    {(filds.length === 0 || filds.includes('contacts')) && (
                      <>
                        <TableCellDesign style={{ maxWidth: 200 }}>
                          {net.contacts.map((con) => (
                            <div key={con.id}>{con.name}</div>
                          ))}
                        </TableCellDesign>
                        <TableCellDesign style={{ maxWidth: 200 }}>
                          {net.contacts.map((con) => (
                            <div key={con.id}>{con.email}</div>
                          ))}
                        </TableCellDesign>
                      </>
                    )}
                    {(filds.length === 0 || filds.includes('exclusionsNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>{net.exclusionsNotes}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('frequencyCappingNotes')) && (
                      <TableCellDesign style={{ maxWidth: 200 }}>{net.frequencyCappingNotes}</TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('adExclusions')) && (
                      <>
                        <TableCellDesign>
                          {net.alcohol !== null ? (net.alcohol === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.cannabis !== null ? (net.cannabis === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.explicitContent !== null ? (net.explicitContent === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.gambling !== null ? (net.gambling === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.pharma !== null ? (net.pharma === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                        <TableCellDesign>
                          {net.politics !== null ? (net.politics === true ? 'Yes' : 'No') : 'Not Set'}{' '}
                        </TableCellDesign>
                      </>
                    )}
                    {/* demographics */}
                    {/* {(filds.length === 0 || filds.includes('age')) && (
                      <TableCellDesign>
                        {net.demoAgeMin && net.demoAgeMax && (
                          <>
                            {net.demoAgeMin}-{net.demoAgeMax}
                          </>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('gender')) && (
                      <TableCellDesign>
                        {net.demoMale && (
                          <div>
                            • Male <br />
                          </div>
                        )}
                        {net.demoFemale && (
                          <div>
                            • Female <br />
                          </div>
                        )}
                        {net.demoUnknown && (
                          <div>
                            • Unknown <br />
                          </div>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('income')) && (
                      <TableCellDesign>
                        {net.demoIncomeMin && net.demoIncomeMax && (
                          <>
                            {net.demoIncomeMin}K-{net.demoIncomeMax}K
                          </>
                        )}
                      </TableCellDesign>
                    )}
                    {(filds.length === 0 || filds.includes('education')) && (
                      <TableCellDesign>
                        {net.demoEducationHsOrLess && (
                          <div>
                            • HS or less <br />
                          </div>
                        )}
                        {net.demoEducationCollegeOrUni && (
                          <div>
                            • College/University <br />
                          </div>
                        )}
                        {net.demoEducationGradU && (
                          <div>
                            • Graduate <br />
                          </div>
                        )}
                        {net.demoEducationOther && (
                          <div>
                            • Other <br />
                            {!!net.demoEducationNotes && <>Note: {net.demoEducationNotes}</>}
                          </div>
                        )}
                      </TableCellDesign>
                    )} */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}
