import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Box,
  Stack,
  Button,
  Drawer,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  FormControlLabel,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Slide,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  InputAdornment,
  Chip,
  Autocomplete,
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { getAllNetworks } from '../../../services/networkServices';
import { addFilter, getFilterByUserId, updateFilter, deleteFilter } from '../../../services/filterService';
import { getItunesCategory } from '../../../services/thirdPartyApiService';

ShopFilterSidebar.propTypes = {
  isPodcast: PropTypes.bool,
  isOpenFilter: PropTypes.bool.isRequired,
  filters: PropTypes.any,
  onOpenFilter: PropTypes.func.isRequired,
  onCloseFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func,
  tableRef: PropTypes.any,
};
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
export default function ShopFilterSidebar({
  isPodcast,
  isOpenFilter,
  filters,
  onOpenFilter,
  onCloseFilter,
  nameSearch,
  category,
  nid,
  networkNames,
}) {
  let timeout = 2000;
  const [userId, setUserId] = useState('');
  const [localSearch, setLocalSearch] = useState('');
  const [categoriesChip, setCategoriesChip] = useState([]);
  const [listName, setListName] = useState('');
  const [allFlt, setAllFlt] = useState([]);
  const [toast, setToast] = useState([]);
  const finallist = [];
  const [data, setData] = useState({});
  const [savedFilters, setSavedFilters] = useState([]);
  const [filteredList, setFilteredList] = useState('new');
  const [networkId, setNetworkId] = useState('');
  const itunesCategories = [
    { id: 1, name: 'Arts' },
    { id: 2, name: 'Books' },
    { id: 3, name: 'Design' },
    { id: 4, name: 'Fashion & Beauty' },
    { id: 5, name: 'Food' },
    { id: 6, name: 'Performing Arts' },
    { id: 7, name: 'Visual Arts' },
    { id: 8, name: 'Business' },
    { id: 9, name: 'Careers' },
    { id: 10, name: 'Entrepreneurship' },
    { id: 11, name: 'Investing' },
    { id: 12, name: 'Management' },
    { id: 13, name: 'Marketing' },
    { id: 14, name: 'Non-Profit' },
    { id: 15, name: 'Comedy' },
    { id: 16, name: 'Comedy Interviews' },
    { id: 17, name: 'Improv' },
    { id: 18, name: 'Stand-Up' },
    { id: 19, name: 'Education' },
    { id: 20, name: 'Courses' },
    { id: 21, name: 'How To' },
    { id: 22, name: 'Language Learning' },
    { id: 23, name: 'Self-Improvement' },
    { id: 24, name: 'Fiction' },
    { id: 25, name: 'Comedy Fiction' },
    { id: 26, name: 'Drama' },
    { id: 27, name: 'Science Fiction' },
    { id: 28, name: 'Government' },
    { id: 29, name: 'History' },
    { id: 30, name: 'Health & Fitness' },
    { id: 31, name: 'Alternative Health' },
    { id: 32, name: 'Fitness' },
    { id: 33, name: 'Medicine' },
    { id: 34, name: 'Mental Health' },
    { id: 35, name: 'Nutrition' },
    { id: 36, name: 'Sexuality' },
    { id: 37, name: 'Kids & Family' },
    { id: 38, name: 'Education for Kids' },
    { id: 39, name: 'Parenting' },
    { id: 40, name: 'Pets & Animals' },
    { id: 41, name: 'Stories for Kids' },
    { id: 42, name: 'Leisure' },
    { id: 43, name: 'Animation & Manga' },
    { id: 44, name: 'Automotive' },
    { id: 45, name: 'Aviation' },
    { id: 46, name: 'Crafts' },
    { id: 47, name: 'Games' },
    { id: 48, name: 'Hobbies' },
    { id: 49, name: 'Home & Garden' },
    { id: 50, name: 'Video Games' },
    { id: 51, name: 'Music' },
    { id: 52, name: 'Music Commentary' },
    { id: 53, name: 'Music History' },
    { id: 54, name: 'Music Interviews' },
    { id: 55, name: 'News' },
    { id: 56, name: 'Business News' },
    { id: 57, name: 'Daily News' },
    { id: 58, name: 'Entertainment News' },
    { id: 59, name: 'News Commentary' },
    { id: 60, name: 'Politics' },
    { id: 61, name: 'Sports News' },
    { id: 62, name: 'Tech News' },
    { id: 63, name: 'Religion & Spirituality' },
    { id: 64, name: 'Buddhism' },
    { id: 65, name: 'Christianity' },
    { id: 66, name: 'Hinduism' },
    { id: 67, name: 'Islam' },
    { id: 68, name: 'Judaism' },
    { id: 69, name: 'Religion' },
    { id: 70, name: 'Spirituality' },
    { id: 71, name: 'Science' },
    { id: 72, name: 'Astronomy' },
    { id: 73, name: 'Chemistry' },
    { id: 74, name: 'Earth Sciences' },
    { id: 75, name: 'Life Sciences' },
    { id: 76, name: 'Mathematics' },
    { id: 77, name: 'Natural Sciences' },
    { id: 78, name: 'Nature' },
    { id: 79, name: 'Physics' },
    { id: 80, name: 'Social Sciences' },
    { id: 81, name: 'Society & Culture' },
    { id: 82, name: 'Documentary' },
    { id: 83, name: 'Personal Journals' },
    { id: 84, name: 'Philosophy' },
    { id: 85, name: 'Places & Travel' },
    { id: 86, name: 'Relationships' },
    { id: 87, name: 'Sports' },
    { id: 88, name: 'Baseball' },
    { id: 89, name: 'Basketball' },
    { id: 90, name: 'Cricket' },
    { id: 91, name: 'Fantasy Sports' },
    { id: 92, name: 'Football' },
    { id: 93, name: 'Golf' },
    { id: 94, name: 'Hockey' },
    { id: 95, name: 'Rugby' },
    { id: 96, name: 'Soccer' },
    { id: 97, name: 'Swimming' },
    { id: 98, name: 'Tennis' },
    { id: 99, name: 'Volleyball' },
    { id: 100, name: 'Wilderness' },
    { id: 101, name: 'Wrestling' },
    { id: 102, name: 'Technology' },
    { id: 103, name: 'True Crime' },
    { id: 104, name: 'TV & Film' },
    { id: 105, name: 'After Shows' },
    { id: 106, name: 'Film History' },
    { id: 107, name: 'Film Interviews' },
    { id: 108, name: 'Film Reviews' },
    { id: 109, name: 'TV Reviews' },
  ];
  const FILTER_LANGUAGES = [
    { value: 'language_En', label: 'English' },
    { value: 'language_Fr', label: 'French' },
  ];

  const FILTER_GEO_OPTIONS = [
    { value: 'geo_Canada', label: 'Canada' },
    { value: 'geo_US', label: 'US' },
    { value: 'geo_Provincial', label: 'Provincial' },
    isPodcast
      ? { value: 'geo_Municipal', label: 'Municipal / FSA' }
      : { value: 'municipalFSA', label: 'Municipal / FSA' },
  ];

  const FILTER_CAN_TRACK = [
    { value: 'canTrack_Chartable', label: 'Chartable' },
    { value: 'canTrack_Podsights', label: 'Podsights' },
    { value: 'canTrack_LoopMe', label: 'LoopMe' },
  ];

  const FILTER_TRAN_TYPE = [
    { value: 'transactionType_Direct', label: 'Direct' },
    { value: 'transactionType_Programmatic', label: 'Programmatic' },
  ];

  const FILTER_AD_TYPE = [
    { value: 'adType_HostRead', label: 'Host Read' },
    { value: 'adType_ClientProvided', label: 'Client Provided' },
    { value: 'adType_NetworkRead', label: 'Network Read' },
    { value: 'adType_Endorsement', label: 'Endorsement' },
  ];
  const FILTER_CPM = [
    { value: 'cpm', label: 'CPM' },
    { value: 'customCPM', label: 'Custom CPM' },
  ];
  const FILTER_AD_TYPE_CPM_HOST_READ = [
    { value: 'cpmToClient_HostRead', label: 'Client' },
    { value: 'cpmToPublisher_HostRead', label: 'Publisher' },
  ];
  const FILTER_AD_TYPE_CPM_CLIENT_PROVIDED = [
    { value: 'cpmToClient_ClientProvided', label: 'Client' },
    { value: 'cpmToPublisher_ClientProvided', label: 'Publisher' },
  ];
  const FILTER_AD_TYPE_CPM_NETWORK_READ = [
    { value: 'cpmToClient_NetworkRead', label: 'Client' },
    { value: 'cpmToPublisher_NetworkRead', label: 'Publisher' },
  ];
  const FILTER_AD_TYPE_CPM_ENDORSEMENT = [
    { value: 'cpmToClient_Endorsement', label: 'Client' },
    { value: 'cpmToPublisher_Endorsement', label: 'Publisher' },
  ];

  const FILTER_AD_POSITION_CPM_PRE_ROLL = [
    { value: 'cpmToClient_PreRoll', label: 'Client' },
    { value: 'cpmToPublisher_PreRoll', label: 'Publisher' },
  ];
  const FILTER_AD_POSITION_CPM_MID_ROLL = [
    { value: 'cpmToClient_MidRoll', label: 'Client' },
    { value: 'cpmToPublisher_MidRoll', label: 'Publisher' },
  ];
  const FILTER_AD_POSITION_CPM_POST_ROLL = [
    { value: 'cpmToClient_PostRoll', label: 'Client' },
    { value: 'cpmToPublisher_PostRoll', label: 'Publisher' },
  ];

  const FILTER_DOWNLOADS = [
    { value: 'monthlyDownloads_CA', label: 'CA' },
    { value: 'monthlyDownloads_US', label: 'US' },
    { value: 'monthlyDownloads_BC', label: 'BC' },
    { value: 'monthlyDownloads_AB', label: 'AB' },
    { value: 'monthlyDownloads_SK', label: 'SK' },
    { value: 'monthlyDownloads_MA', label: 'MA' },
    { value: 'monthlyDownloads_ON', label: 'ON' },
    { value: 'monthlyDownloads_QB', label: 'QB' },
    { value: 'monthlyDownloads_AT', label: 'AT' },
  ];
  const FILTER_AD_POSITION = [
    { value: 'adPosition_Pre', label: 'Pre' },
    { value: 'adPosition_Mid', label: 'Mid' },
    { value: 'adPosition_Post', label: 'Post' },
  ];

  const FILTER_IMPRESSIONS = [
    { value: 'monthlyImpressions_CA', label: 'CA' },
    { value: 'monthlyImpressions_US', label: 'US' },
    { value: 'monthlyImpressions_BC', label: 'BC' },
    { value: 'monthlyImpressions_AB', label: 'AB' },
    { value: 'monthlyImpressions_SK', label: 'SK' },
    { value: 'monthlyImpressions_MA', label: 'MA' },
    { value: 'monthlyImpressions_ON', label: 'ON' },
    { value: 'monthlyImpressions_QB', label: 'QB' },
    { value: 'monthlyImpressions_AT', label: 'AT' },
  ];

  const FILTER_RESTRICTIONS = [
    { value: 'alcohol', label: 'Alcohol' },
    { value: 'cannabis', label: 'Cannabis' },
    { value: 'explicitContent', label: 'Explicit Content' },
    { value: 'gambling', label: 'Gambling' },
    { value: 'pharma', label: 'Pharma' },
    { value: 'politics', label: 'Politics' },
  ];

  useEffect(() => {
    if (localStorage.getItem('userData') !== null) {
      setUserId(JSON.parse(localStorage.getItem('userData')).id);
    }
  }, []);
  const FILTER_COLOR_OPTIONS = ['#00AB55', '#000000', '#FFFFFF', '#FFC0CB', '#FF4842', '#1890FF', '#94D82D', '#FFC107'];
  const clearAll = () => {
    setAllFlt([...[]]);
    setLocalSearch('');
    setNetworkId('');
    setCategoriesChip([...[]]);
  };

  const handleChange = () => {
    nameSearch(localSearch);
    if (isPodcast) {
      nid(networkId);
      category(categoriesChip);
    }
    filters(allFlt);
    onCloseFilter(onCloseFilter);
  };

  useEffect(() => {
    // creating dropdown
    if (userId !== '') {
      getFilterByUserId(userId).then((res) => {
        if (res?.value?.success) {
          const arr = res.value.data;
          setSavedFilters([...arr]);
        }
        else {
          console.log("no response from getFilterByUserId");
        }
      });
    }
    if (filteredList === 'new') {
      setAllFlt([...[]]);
      setCategoriesChip([...[]]);
      setLocalSearch('');
      setNetworkId('');
      setListName('');
    } else {
      const data = savedFilters.filter((dt) => dt.id === filteredList);
      const arr = JSON.parse(data[0].filterString);
      setListName(data[0].name);
      setLocalSearch(arr[0].search);
      setNetworkId(arr[0].networkId);
      setCategoriesChip([...arr[0].category]);
      setAllFlt(arr[1]);
    }
  }, [filteredList, userId]);

  const simpleFilter = (field, val) => {
    const flts = allFlt;
    if (val) {
      flts.push({ flt: field, type: 'simple' });
    } else {
      const index = flts
        .map((e) => {
          return e.flt;
        })
        .indexOf(field);
      flts.splice(index, 1);
    }
    setAllFlt([...flts]);
  };

  const handleSearch = () => {
    finallist.push({ search: localSearch, networkId, category: categoriesChip });
  };

  const handleDataChange = () => {
    finallist.push(allFlt);
    if (filteredList !== 'new') {
      data.id = filteredList;
    }
    data.name = listName;
    data.userId = userId;
    data.filterstring = JSON.stringify(finallist);
    setData({ ...data });
  };

  const handleFilter = (op) => {
    if (op === 'add') {
      if (data.name !== '') {
        addFilter(data).then((res) => {
          if (res.success) {
            savedFilters.push(res.data);
            setFilteredList(res.data.id);
            handleToast('toastFlag', true);
            handleToast('toastMsg', 'Filter Added Successfully');
            handleToast('toastStatus', 'success');
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              handleToast('toastFlag', false);
            }, 2000);
          }
        });
      } else {
        handleToast('toastFlag', true);
        handleToast('toastMsg', 'List Name is Mandatory');
        handleToast('toastStatus', 'error');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          handleToast('toastFlag', false);
        }, 1500);
      }
    } else if (op === 'save') {
      updateFilter(data).then((res) => {
        if (res.success) {
          handleToast('toastFlag', true);
          handleToast('toastMsg', 'Filter Updated Successfully');
          handleToast('toastStatus', 'success');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleToast('toastFlag', false);
          }, 2000);
        }
      });
    } else {
      deleteFilter(filteredList).then((res) => {
        if (res.success) {
          handleToast('openDialog', false);
          handleToast('toastFlag', true);
          handleToast('toastMsg', 'Filter Deleted Successfully');
          handleToast('toastStatus', 'error');
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            handleToast('toastFlag', false);
          }, 2000);
          setFilteredList('new');
        }
      });
    }
  };

  const handleToast = (fld, val) => {
    toast[fld] = val;
    setToast({ ...toast });
  };

  const rangeFilter = (field, val, rangeType) => {
    let flts = allFlt;
    flts = flts.map((e, i) => {
      e.index = i;
      return e;
    });
    if (Number.isNaN(val)) {
      const data = flts.filter((elem) => {
        return elem.flt === field && elem.rangeType === rangeType;
      });
      if (data.length > 0) {
        flts.splice(data[0].index, 1);
      }
    } else {
      const data = flts.filter((elem) => {
        return elem.flt === field && elem.rangeType === rangeType;
      });
      if (data.length > 0) {
        flts[data[0].index].val = val;
      } else {
        const dt = {
          flt: field,
          type: 'range',
          val,
          rangeType,
        };
        flts.push(dt);
      }
    }
    setAllFlt([...flts]);
  };

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>
      <Drawer
        anchor="left"
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            {/* Dropdown */}
            <div>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Saved List</InputLabel>
                  <Select
                    value={filteredList}
                    label="Saved List"
                    onChange={(e) => {
                      setFilteredList(e.target.value);
                    }}
                  >
                    <MenuItem value="new">New List</MenuItem>
                    {savedFilters.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* Dropdown for Network */}
            {isPodcast ? (
              <div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel>Network Name</InputLabel>
                    <Select
                      label="Network Name"
                      value={networkId}
                      onChange={(e) => {
                        setNetworkId(e.target.value);
                      }}
                    >
                      <MenuItem value="">Select Network</MenuItem>
                      {networkNames.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            ) : null}
            {/* Dropdown for Category */}
            {isPodcast ? (
              <div>
                <Box sx={{ minWidth: 120 }}>
                  <Autocomplete
                    value={categoriesChip}
                    multiple
                    freeSolo
                    id="tags-filled"
                    options={itunesCategories.map((dt) => dt.name)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => <Chip variant="filled" label={option} {...getTagProps({ index })} />)
                    }
                    renderInput={(params) => <TextField {...params} label="Category" placeholder="Type and select" />}
                    onChange={(e, value) => {
                      setCategoriesChip(value);
                    }}
                    style={{ maxWidth: '1250px' }}
                  />
                </Box>
              </div>
            ) : null}
            {/* search */}
            <div>
              <FormGroup>
                <TextField
                  sx={{ flex: 1 }}
                  label="Search by name"
                  value={localSearch}
                  onChange={(e) => {
                    setLocalSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleChange();
                    }
                  }}
                  InputProps={{
                    endAdornment: localSearch && (
                      <IconButton onClick={() => setLocalSearch('')}>
                        <Iconify icon="twemoji:heavy-multiplication-x" width={20} height={20} />
                      </IconButton>
                    ),
                  }}
                />
              </FormGroup>
            </div>
            {/* Languages */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Languages
              </Typography>
              <FormGroup>
                {FILTER_LANGUAGES.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e, v) => {
                      simpleFilter(item.value, v);
                    }}
                    control={
                      <Checkbox
                        checked={
                          allFlt
                            .map((e) => {
                              return e.flt;
                            })
                            .indexOf(item.value) > -1
                        }
                      />
                    }
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            {/* Geo */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Geo
              </Typography>
              <FormGroup>
                {FILTER_GEO_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e, v) => {
                      simpleFilter(item.value, v);
                    }}
                    control={
                      <Checkbox
                        checked={
                          allFlt
                            .map((e) => {
                              return e.flt;
                            })
                            .indexOf(item.value) > -1
                        }
                      />
                    }
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            {/* Can track */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Can Track
              </Typography>
              <FormGroup>
                {FILTER_CAN_TRACK.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e, v) => {
                      simpleFilter(item.value, v, 'simple');
                    }}
                    control={
                      <Checkbox
                        checked={
                          allFlt
                            .map((e) => {
                              return e.flt;
                            })
                            .indexOf(item.value) > -1
                        }
                      />
                    }
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            {/* Transaction type */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Transaction Type
              </Typography>
              <FormGroup>
                {FILTER_TRAN_TYPE.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e, v) => {
                      simpleFilter(item.value, v, 'simple');
                    }}
                    control={
                      <Checkbox
                        checked={
                          allFlt
                            .map((e) => {
                              return e.flt;
                            })
                            .indexOf(item.value) > -1
                        }
                      />
                    }
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            {/* Ad Read type */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Read Type
              </Typography>
              <FormGroup>
                {FILTER_AD_TYPE.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e, v) => {
                      simpleFilter(item.value, v);
                    }}
                    control={
                      <Checkbox
                        checked={
                          allFlt
                            .map((e) => {
                              return e.flt;
                            })
                            .indexOf(item.value) > -1
                        }
                      />
                    }
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
            {/* CPM */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                CPM
              </Typography>
              <FormGroup>
                {FILTER_CPM.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '100px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, parseInt(e.target.value, 10), 'max')}
                    />
                    <Typography sx={{ width: '100px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Read type CPM Host Read */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Read Type CPM Host Read
              </Typography>
              <FormGroup>
                {FILTER_AD_TYPE_CPM_HOST_READ.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, parseInt(e.target.value, 10), 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Read type CPM Client Provided */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Read Type CPM Client Provided
              </Typography>
              <FormGroup>
                {FILTER_AD_TYPE_CPM_CLIENT_PROVIDED.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Read type CPM Network Read */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Read Type CPM Network Read
              </Typography>
              <FormGroup>
                {FILTER_AD_TYPE_CPM_NETWORK_READ.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Read type CPM Endorsement */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Read Type CPM Endorsement
              </Typography>
              <FormGroup>
                {FILTER_AD_TYPE_CPM_ENDORSEMENT.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Monthly Downloads */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Monthly Downloads
              </Typography>
              <FormGroup>
                {FILTER_DOWNLOADS.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Min"
                      sx={{ width: '90px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'min';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'min';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'min')}
                    />
                    <Typography sx={{ width: '30px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                    <TextField
                      label="Max"
                      sx={{ width: '90px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'max')}
                    />
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Monthly Impressions */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Monthly Impressions
              </Typography>
              <FormGroup>
                {FILTER_IMPRESSIONS.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Min"
                      type="number"
                      sx={{ width: '90px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'min';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'min';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'min')}
                    />
                    <Typography sx={{ width: '30px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                    <TextField
                      label="Max"
                      sx={{ width: '90px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, e.target.value, 'max')}
                    />
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Position */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Position
              </Typography>
              <FormGroup>
                {FILTER_AD_POSITION.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, parseInt(e.target.value, 10), 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Position CPM Pre Roll */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Position CPM Pre Roll
              </Typography>
              <FormGroup>
                {FILTER_AD_POSITION_CPM_PRE_ROLL.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, parseInt(e.target.value, 10), 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Position CPM Mid Roll */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Position CPM Mid Roll
              </Typography>
              <FormGroup>
                {FILTER_AD_POSITION_CPM_MID_ROLL.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, parseInt(e.target.value, 10), 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Ad Position CPM Post Roll */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Ad Position CPM Post Roll
              </Typography>
              <FormGroup>
                {FILTER_AD_POSITION_CPM_POST_ROLL.map((item) => (
                  <Stack direction="row" key={item.value}>
                    <TextField
                      label="Max"
                      sx={{ width: '120px', mb: '5px' }}
                      value={
                        allFlt.filter((elem) => {
                          return elem.flt === item.value && elem.rangeType === 'max';
                        }).length > 0
                          ? allFlt.filter((elem) => {
                              return elem.flt === item.value && elem.rangeType === 'max';
                            })[0].val
                          : ''
                      }
                      onChange={(e) => rangeFilter(item.value, parseInt(e.target.value, 10), 'max')}
                    />
                    <Typography sx={{ width: '70px', m: '15px 5px 5px 5px' }}>{item.label}</Typography>
                  </Stack>
                ))}
              </FormGroup>
            </div>
            {/* Restrictions */}
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Restrictions
              </Typography>
              <FormGroup>
                {FILTER_RESTRICTIONS.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    onChange={(e, v) => {
                      simpleFilter(item.value, v);
                    }}
                    control={
                      <Checkbox
                        checked={
                          allFlt
                            .map((e) => {
                              return e.flt;
                            })
                            .indexOf(item.value) > -1
                        }
                      />
                    }
                    value={item.value}
                    label={item.label}
                  />
                ))}
              </FormGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 2 }}>
          {filteredList === 'new' ? (
            <Stack direction="row" sx={{ pt: 1 }}>
              <TextField
                sx={{ height: '56px', width: '120px', mr: 1 }}
                label="List Name"
                onChange={(e) => {
                  setListName(e.target.value);
                }}
              />
              <Button
                sx={{ height: '56px', width: '120px' }}
                size="medium"
                type="submit"
                color="success"
                variant="outlined"
                onClick={() => {
                  handleSearch();
                  handleDataChange();
                  handleFilter('add');
                }}
                startIcon={<Iconify icon="material-symbols:add-circle-outline" />}
              >
                Add
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" sx={{ pt: 1 }}>
              <Button
                fullWidth
                sx={{ mr: 1 }}
                size="medium"
                type="submit"
                color="error"
                variant="outlined"
                onClick={() => {
                  handleToast('openDialog', true);
                }}
                startIcon={<Iconify icon="eva:trash-2-outline" />}
              >
                Delete
              </Button>
              <Button
                fullWidth
                size="medium"
                type="submit"
                color="success"
                variant="outlined"
                onClick={() => {
                  handleSearch();
                  handleDataChange();
                  handleFilter('save');
                }}
                startIcon={<Iconify icon="material-symbols:save-rounded" />}
              >
                Save
              </Button>
            </Stack>
          )}
          <Stack direction="row" sx={{ pt: 1 }}>
            <Button
              fullWidth
              sx={{ mr: 1 }}
              size="medium"
              type="submit"
              variant="outlined"
              onClick={() => clearAll()}
              startIcon={<Iconify icon="ic:round-clear-all" />}
            >
              Clear All
            </Button>
            <Button
              fullWidth
              size="medium"
              type="submit"
              variant="outlined"
              onClick={() => handleChange()}
              startIcon={<Iconify icon="material-symbols:arrow-circle-up-outline-rounded" />}
            >
              Apply
            </Button>
          </Stack>
        </Box>
        <Snackbar open={toast.toastFlag} TransitionComponent={SlideTransition}>
          <Alert severity={toast.toastStatus}>{toast.toastMsg}</Alert>
        </Snackbar>
        <Dialog
          open={toast.openDialog || false}
          onClose={() => {
            handleToast('openDialog', false);
          }}
        >
          <DialogContent>
            <Typography>Are you sure you want to delete "{listName}" list</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleToast('openDialog', false)}>Close</Button>
            <Button
              onClick={() => {
                handleFilter('delete');
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    </>
  );
}
