export function get(request, params, options) {
  const url = new URL(request);
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: options.headers,
      crossorigin: true,
    })
      .then((response) => response)
      .then((res) => {
        resolve(res);
        if (res.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('userData');
          const url = window.location.toString();
          // eslint-disable-next-line no-restricted-globals
          window.location = url.replace(location.pathname, '/login');
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function post(request, params, options) {
  const url = request;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: options.headers,
      body: params,
      crossDomain: true,
    })
      .then((response) => response)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function del(request, params, options) {
  const url = request;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      headers: options.headers,
      body: params,
      crossDomain: true,
    })
      .then((response) => response)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
