import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import NetworksList from './pages/NetworksList';
import PodcastList from './pages/PodcastList';
import NetworkDetail from './pages/NetworkDetail';
import PodcastDetail from './pages/PodcastDetails';
import ImportNetwork from './pages/ImportNetwork';
import AuthUser from './AuthUser';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (<><AuthUser/><DashboardLayout /></>),
      children: [
        { path: 'networks', element: <NetworksList />},
        { path: 'networks/:id', element: <NetworkDetail /> },
        { path: 'podcasts', element: <PodcastList /> },
        { path: 'podcasts/:id', element: <PodcastDetail /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'importnetwork', element: <ImportNetwork /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/networks" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
