import * as serviceContext from './serviceContext';

const { REACT_APP_API_ENDPOINT } = process.env;

const options = {};
const headers = new Headers();
let res = '';

headers.append('Access-Control-Allow-Origin', '*');
headers.append('mode', 'cors');
headers.append('Content-Type', 'application/json');

options.headers = headers;

export async function addContact(data) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.post(
      `${REACT_APP_API_ENDPOINT}/contacts/create`,
      JSON.stringify(data),
      options
    );
    if (response.status === 403) {
      res = response;
    } else {
      res = response.json();
    }
    return res;
  } catch (error) {
    return error;
  }
}

export async function updateContact(data) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.post(
      `${REACT_APP_API_ENDPOINT}/contacts/update`,
      JSON.stringify(data),
      options
    );
    if (response.status === 403) {
      res = response;
    } else {
      res = response.json();
    }
    return res;
  } catch (error) {
    return error;
  }
}

export async function deleteContact(id) {
  try {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    options.headers.set('Authorization', `Bearer ${tokenData}`);
    const response = await serviceContext.del(`${REACT_APP_API_ENDPOINT}/contacts?id=${id}`, null, options);
    if (response.status === 403) {
      res = response;
    } else {
      res = response.json();
    }
    return res;
  } catch (error) {
    return error;
  }
}
