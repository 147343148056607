let data = [];
const apiKey = 'DVEDYJTFVXHZM7TVAG4Y';
const apiSecret = 'wwSyQFfBY7Kn4L#TfaqR83cUPZPzQhw5#MWk$RBY';
const encoder = new TextEncoder();

export async function getThirdPartyAPIPodcast(search) {
  const apiHeaderTime = Math.floor(Date.now() / 1000);
  let data4Hash = apiKey + apiSecret + apiHeaderTime;
  const encodedData4hash = encoder.encode(data4Hash);
  const hashbuffer = await crypto.subtle.digest('SHA-1', encodedData4hash);
  const hashArray = Array.from(new Uint8Array(hashbuffer));
  data4Hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  const options = {
    method: 'get',
    headers: {
      // not needed right now, maybe in future:  "Content-Type": "application/json",
      'X-Auth-Date': apiHeaderTime,
      'X-Auth-Key': apiKey,
      Authorization: data4Hash,
      'User-Agent': 'SuperPodcastPlayer/1.8',
    },
  };
  try {
    const url = `https://api.podcastindex.org/api/1.0/search/byterm?q=${search}&aponly=true`;
    return fetch(url, options)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        data = json.feeds;
        return data;
      });
  } catch (error) {
    return error;
  }
}
export async function getThirdPartyAPIPodcastByTitle(search) {
  const apiHeaderTime = Math.floor(Date.now() / 1000);
  let data4Hash = apiKey + apiSecret + apiHeaderTime;
  const encodedData4hash = encoder.encode(data4Hash);
  const hashbuffer = await crypto.subtle.digest('SHA-1', encodedData4hash);
  const hashArray = Array.from(new Uint8Array(hashbuffer));
  data4Hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  const options = {
    method: 'get',
    headers: {
      // not needed right now, maybe in future:  "Content-Type": "application/json",
      'X-Auth-Date': apiHeaderTime,
      'X-Auth-Key': apiKey,
      Authorization: data4Hash,
      'User-Agent': 'SuperPodcastPlayer/1.8',
    },
  };
  try {
    const url = `https://api.podcastindex.org/api/1.0/search/bytitle?q=${search}&aponly=true`;
    return fetch(url, options)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        data = json.feeds;
        return data;
      });
  } catch (error) {
    return error;
  }
}
export async function getItunesCategory() {
  const apiHeaderTime = Math.floor(Date.now() / 1000);
  let data4Hash = apiKey + apiSecret + apiHeaderTime;
  const encodedData4hash = encoder.encode(data4Hash);
  const hashbuffer = await crypto.subtle.digest('SHA-1', encodedData4hash);
  const hashArray = Array.from(new Uint8Array(hashbuffer));
  data4Hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  const options = {
    method: 'get',
    headers: {
      // not needed right now, maybe in future:  "Content-Type": "application/json",
      'X-Auth-Date': apiHeaderTime,
      'X-Auth-Key': apiKey,
      Authorization: data4Hash,
      'User-Agent': 'SuperPodcastPlayer/1.8',
    },
  };
  try {
    const url = `https://api.podcastindex.org/api/1.0/categories/list`;
    return fetch(url, options)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        data = json.feeds;
        return data;
      });
  } catch (error) {
    return error;
  }
}
export async function getUpdates(id) {
  const apiHeaderTime = Math.floor(Date.now() / 1000);
  let data4Hash = apiKey + apiSecret + apiHeaderTime;
  const encodedData4hash = encoder.encode(data4Hash);
  const hashbuffer = await crypto.subtle.digest('SHA-1', encodedData4hash);
  const hashArray = Array.from(new Uint8Array(hashbuffer));
  data4Hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  const options = {
    method: 'get',
    headers: {
      // not needed right now, maybe in future:  "Content-Type": "application/json",
      'X-Auth-Date': apiHeaderTime,
      'X-Auth-Key': apiKey,
      Authorization: data4Hash,
      'User-Agent': 'SuperPodcastPlayer/1.8',
    },
  };
  try {
    const url = `https://api.podcastindex.org/api/1.0/podcasts/byfeedid?id=${id}`;
    return fetch(url, options)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if(json.status !== "false"){
          data = json.feed;
        }
        else{
          data = json
        }
        return data;
      });
  } catch (error) {
    return error;
  }
}
